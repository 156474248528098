//  Modules from the React eco-system
import React, { useState } from "react"

// Own components
import AdminInfo from "../admin-info.component"
import ProductInfos from "./product-infos.component"
import ProductImageUploader from "./product-image-uploader-modal.component"

// interfaces, enums
import { IProduct } from "../../interfaces/cubes"

// bootstrap, icons
import { Row, Col, Button } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiFormatQuoteClose } from "@mdi/js"

interface IProps {
    product: IProduct
    setProduct: Function
}

function ProductDetails(props: IProps) {
    // show image uploader
    const [showUploader, setShowUploader] = useState<boolean>(false)

    return (
        <div className="product-details">
            <Row>
                <Col xs={12} md={4}>
                    <ProductInfos product={props.product} setProduct={props.setProduct} />
                </Col>
                <Col xs={12} md={4}>
                    <AdminInfo admin={props.product.supplier} />
                </Col>
                <Col xs={12} md={4}>
                    <div className="tile product-description">
                        <div className="orange-icon-container">
                            <Icon path={mdiFormatQuoteClose} size={1} />
                        </div>

                        <p>{props.product.description}</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4}>
                    <div className="tile product-image-tile">
                        {props.product.image ? (
                            <div className="product-image-container">
                                <img src={props.product.image} alt="product" />
                            </div>
                        ) : (
                            <Button className="btn orange" onClick={() => setShowUploader(true)}>
                                Upload image
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
            <ProductImageUploader show={showUploader} handleClose={() => setShowUploader(false)} productid={props.product._id} setProduct={props.setProduct} />
        </div>
    )
}

export default ProductDetails
