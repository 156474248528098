//  Modules from the React eco-system
import React, { useMemo, useState } from "react"

// Third-party modules
import moment from "moment"

// Own components
import TableDisplayer from "../table.component"
import { dateFilter, textFilter } from "../column-filter.component"

// interfaces, enums
import { IMainCategory } from "../../interfaces/cubes"

// bootstrap, icons
import Icon from "@mdi/react"
import { mdiShapeOutline, mdiPencilOutline } from "@mdi/js"
import { Button } from "react-bootstrap"
import UpdateCategory from "./update-category-modal.component"

interface IProps {
    categories: IMainCategory[]
    hiddenColumns?: string[]
    setCategories?: Function
}

function CategoryTable(props: IProps) {
    const [showUpdateCategory, setShowUpdateCategory] = useState<boolean>(false)

    const [selectedCategory, setSelectedCategory] = useState<IMainCategory>()

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("name"),
            },

            {
                Header: "Second name",
                accessor: "second_name",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("second_name"),
            },

            {
                Header: "Image",
                accessor: "image",
                Cell: (data: any) => {
                    return (
                        <div>
                            {data.value ? (
                                <img className="table-image" src={data.value} alt="" />
                            ) : (
                                <div className="table-icon-container">
                                    <Icon path={mdiShapeOutline} size={1} />
                                </div>
                            )}
                        </div>
                    )
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("image"),
            },
            {
                Header: () => null, // No header
                id: "edit", // It needs an ID
                Cell: (data: any) => {
                    return (
                        <div>
                            <Button
                                className="orange"
                                onClick={() => {
                                    console.log("DATTT", data.row.original.name)

                                    setSelectedCategory(data.row.original)
                                    setShowUpdateCategory(true)
                                }}>
                                <Icon path={mdiPencilOutline} size={1} />
                            </Button>
                        </div>
                    )
                },
                disableFilters: true,
            },

            {
                Header: "Created at",
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("createdAt"),
            },
        ],

        [props.hiddenColumns]
    )

    return (
        <div>
            {props.categories ? (
                <>
                    <TableDisplayer columns={columns} data={props.categories} />
                    {selectedCategory && <UpdateCategory category={selectedCategory} show={showUpdateCategory} handleClose={setShowUpdateCategory} setCategories={props.setCategories} />}
                </>
            ) : (
                <></>
            )}
        </div>
    )
}

export default CategoryTable
