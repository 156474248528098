import { FormikProps } from "formik"
import React, { useState, useEffect } from "react"
import { Form, Button, Row, Col } from "react-bootstrap"
import axiosClient from "../../api/api"
import { IMainCategory } from "../../interfaces/cubes"
import { ISupplier } from "../../interfaces/persons"
import Select from "react-select"
import { SingleValue } from "react-select"
import CreateCategory from "../products/create-category-modal.component"
import { toast } from "react-toastify"

interface IProps {
    formik: FormikProps<any>
}

interface IOption {
    readonly label: string
    readonly value: string
}

function ProductForm(props: IProps) {
    const [mainCategories, setMainCategories] = useState<IOption[]>([])

    const [suppliers, setSuppliers] = useState<IOption[]>([])

    const [showCreateCategory, setShowCreateCategory] = useState(false)

    useEffect(() => {
        getSelectableOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getSelectableOptions() {
        try {
            const response = await axiosClient.get(`/category`)
            setMainCategories(
                response.data.map((category: IMainCategory) => {
                    return { label: category.name, value: category._id }
                })
            )

            if ("supplier" in props.formik.values) {
                const response = await axiosClient.get(`/supplier`)

                setSuppliers(
                    response.data.map((supplier: ISupplier) => {
                        return { label: `${supplier.firstname} ${supplier.lastname}`, value: supplier._id }
                    })
                )
            }
        } catch (error) {
            toast.error("Failed to get main categories")
        }
    }

    return (
        <Form onSubmit={props.formik.handleSubmit}>
            <Row>
                <Col xs={12} sm={4}>
                    <Form.Group className="mb-3" controlId="formSupplier">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            onChange={props.formik.handleChange}
                            name="name"
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.name}
                            type="text"
                            placeholder="Name of the product..."
                        />
                        {props.formik.touched.name && props.formik.errors.name ? <p className="input-error">{props.formik.errors.name}</p> : null}
                    </Form.Group>
                </Col>
                <Col xs={12} sm={4}>
                    <Form.Group className="mb-3" controlId="formSupplier">
                        <Form.Label>User price</Form.Label>
                        <Form.Control
                            onChange={props.formik.handleChange}
                            name="userPrice"
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.userPrice}
                            type="number"
                            placeholder="User price..."
                        />
                        {props.formik.touched.userPrice && props.formik.errors.userPrice ? <p className="input-error">{props.formik.errors.userPrice}</p> : null}
                    </Form.Group>
                </Col>
                <Col xs={12} sm={4}>
                    <Form.Group className="mb-3" controlId="formSupplier">
                        <Form.Label>Purchase price</Form.Label>
                        <Form.Control
                            onChange={props.formik.handleChange}
                            name="purchasePrice"
                            onBlur={props.formik.handleBlur}
                            value={props.formik.values.purchasePrice}
                            type="number"
                            placeholder="Purchase price..."
                        />
                        {props.formik.touched.purchasePrice && props.formik.errors.purchasePrice ? <p className="input-error">{props.formik.errors.purchasePrice}</p> : null}
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <Form.Group className="mb-3" controlId="formSupplier">
                        <Form.Label>
                            Main Category{" "}
                            <Button className="orange" onClick={() => setShowCreateCategory(true)}>
                                Create Category
                            </Button>
                        </Form.Label>
                        <Select
                            onChange={(newValue: SingleValue<{ label: string; value: string }>) => newValue && props.formik.setFieldValue("mainCategory", newValue)}
                            options={mainCategories}
                            value={props.formik.values.mainCategory}
                        />
                        {props.formik.touched.mainCategory && props.formik.errors.mainCategory ? <p className="input-error">{props.formik.errors.mainCategory}</p> : null}
                    </Form.Group>
                </Col>
            </Row>

            {"supplier" in props.formik.values ? (
                <Row>
                    <Col xs={12}>
                        <Form.Group className="mb-3" controlId="formSupplier">
                            <Form.Label>Supplier</Form.Label>
                            <Select
                                onChange={(newValue: SingleValue<{ label: string; value: string }>) => newValue && props.formik.setFieldValue("supplier", newValue)}
                                options={suppliers}
                                value={props.formik.values.supplier}
                            />
                            {props.formik.touched.mainCategory && props.formik.errors.mainCategory ? <p className="input-error">{props.formik.errors.mainCategory}</p> : null}
                        </Form.Group>
                    </Col>
                </Row>
            ) : (
                <></>
            )}

            <Form.Group className="mb-3" controlId="formSupplier">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={2}
                    onChange={props.formik.handleChange}
                    name="shortDescription"
                    onBlur={props.formik.handleBlur}
                    value={props.formik.values.shortDescription}
                    type="text"
                    placeholder="Short description of the product..."
                />
                {props.formik.touched.shortDescription && props.formik.errors.shortDescription ? <p className="input-error">{props.formik.errors.shortDescription}</p> : null}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSupplier">
                <Form.Label>Long Description</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    onChange={props.formik.handleChange}
                    name="description"
                    onBlur={props.formik.handleBlur}
                    value={props.formik.values.description}
                    type="text"
                    placeholder="Description of the product..."
                />
                {props.formik.touched.description && props.formik.errors.description ? <p className="input-error">{props.formik.errors.description}</p> : null}
            </Form.Group>
            <Row>
                <Col xs={12} sm={4} md={3} lg={2}>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="Enabled for the users"
                            onChange={props.formik.handleChange}
                            name="enabled"
                            onBlur={props.formik.handleBlur}
                            checked={props.formik.values.enabled}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={4} md={3} lg={2}>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="Highlighted in the app"
                            onChange={props.formik.handleChange}
                            name="highlightedInApp"
                            onBlur={props.formik.handleBlur}
                            checked={props.formik.values.highlightedInApp}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={4} md={3} lg={2}>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="Shop product"
                            onChange={props.formik.handleChange}
                            name="shopProduct"
                            onBlur={props.formik.handleBlur}
                            checked={props.formik.values.shopProduct}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Button className="orange" type="submit">
                Submit
            </Button>
            <CreateCategory show={showCreateCategory} handleClose={() => setShowCreateCategory(false)} onCategoryCreation={getSelectableOptions} />
        </Form>
    )
}

export default ProductForm
