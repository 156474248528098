import React, { useState, useEffect } from "react"
import { GoogleMap, LoadScript, StandaloneSearchBox } from "@react-google-maps/api"
import GoogleMapMarker from "./google-map-marker.component"
import { IMarkerData, IPosition } from "../interfaces/pages"

const containerStyle = {
    width: "100%",
    height: "100%",
    minWidth: "200px",
    minHeight: "200px",
}

const defaultCenter = {
    lat: 30,
    lng: 30,
}

interface IProps {
    center?: IPosition
    zoom?: number
    onClick?: Function
    markerDatas?: IMarkerData[]
}

function CustomGoogleMap(props: IProps) {
    const [searchBox, setSearchBox] = useState<any>(null)

    const [centerOfMap, setcenterOfMap] = useState<IPosition>()

    const apiKey = 'AIzaSyC_umiOWXF3LyntTdlu75Cvo8RE0Ufkbzo';

    useEffect(() => {
        if (props.center) {
            setcenterOfMap(props.center)
        } else {
            setcenterOfMap(defaultCenter)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleOnClick(e: google.maps.MapMouseEvent) {
        if (props.onClick) {
            const lat = e.latLng?.lat()
            const lng = e.latLng?.lng()

            if (lat && lng) {
                props.onClick(lat, lng)
            }
        }
    }

    const onLoad = (ref: any) => {
        setSearchBox(ref)
    }

    const onPlacesChanged = () => setcenterOfMap({ lat: searchBox.getPlaces()[0].geometry.location.lat(), lng: searchBox.getPlaces()[0].geometry.location.lng() })
    return (
        <LoadScript googleMapsApiKey={apiKey || ""} libraries={["places"]}>
            <GoogleMap mapContainerClassName="map" onClick={(e) => handleOnClick(e)} mapContainerStyle={containerStyle} center={centerOfMap} zoom={props.zoom || 7}>
                <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                    <input type="text" placeholder="Search..." className="searchbox" />
                </StandaloneSearchBox>

                {props.markerDatas?.map((data, index) => {
                    return <GoogleMapMarker key={index} markerData={data} />
                })}
            </GoogleMap>
        </LoadScript>
    )
}

export default React.memo(CustomGoogleMap)
