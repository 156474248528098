// Modules from the React eco-system
import { useState, useContext } from "react"
import moment from "moment"
import Select from "react-select"

// Third-party modules
import { toast } from "react-toastify"

// axios
import axiosClient from "../../api/api"

// Redux, store
import { UserContext } from "../../store/UserProvider"

// interfaces, enums
import { EOrderStatus, ERoles } from "../../constants/enum"
import { IOrder, IReservation } from "../../interfaces/cubes"

// own components
import AddServiceModal from "./add-service-modal.component"
import AddProductModal from "./add-product-modal.component"
import OrdersTable from "./orders-table.component"
import OrderImageUploader from "./order-image-uploader.component"

// style, bootstrap, icons
import Icon from "@mdi/react"
import { mdiBasketPlusOutline, mdiBasketOutline, mdiPlus } from "@mdi/js"
import { Row, Col, Button, Popover, OverlayTrigger } from "react-bootstrap"

interface IProps {
    orders: IOrder[]
    setReservation: Function
    reservationId: string
    regionid: string
}

interface IOrderStatusOption {
    value: EOrderStatus
    label: EOrderStatus
}
const orderStatusOptions: IOrderStatusOption[] = [
    { value: EOrderStatus.OPEN, label: EOrderStatus.OPEN },
    { value: EOrderStatus.ACCEPTED, label: EOrderStatus.ACCEPTED },
    { value: EOrderStatus.COMPLETE, label: EOrderStatus.COMPLETE },
]

function ReservationOrders(props: IProps) {
    //Logged in user
    const { user } = useContext(UserContext)

    // show/hide modals
    const [showAddServiceModal, setshowAddServiceModal] = useState<boolean>(false)
    const [showAddProductModal, setshowAddProductModal] = useState<boolean>(false)
    const [showOrderImageModal, setShowOrderImageModal] = useState<boolean>(false)

    /**
     * add order to the reservation
     */
    async function addOrder() {
        try {
            const response = await axiosClient.post(`/reservation/order`, { reservationid: props.reservationId })
            props.setReservation((prev: IReservation) => {
                return { ...prev, orders: [...prev.orders, response.data] }
            })
            toast.success("Order is created")
        } catch (error) {
            toast.error("Failed to create order")
        }
    }

    /**
     * delete order from the reservation
     * @param order
     */
    async function deleteOrder(order: IOrder) {
        if (order.order_items.length !== 0) {
            toast.error("Order with order item(s) is not removable")
            return
        }

        try {
            const response = await axiosClient.delete(`/reservation/order/${order._id}`)
            props.setReservation((prev: IReservation) => {
                return { ...prev, orders: prev.orders.filter((order) => order._id !== response.data._id) }
            })
            toast.success("The order is removed")
        } catch (error) {
            toast.error("Failed to delete the order")
        }
    }

    /**
     * popover for the delete button
     */
    function displayDeletePopover(order: IOrder) {
        const popover = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">Delete order</Popover.Header>
                <Popover.Body>
                    <div>
                        <p>Delete this order?</p>
                        <Button className="btn orange" onClick={() => deleteOrder(order)}>
                            Delete
                        </Button>
                    </div>
                </Popover.Body>
            </Popover>
        )
        return popover
    }

    /**
     * update order
     * @param updateData
     * @param orderId
     */
    async function updateOrder(updateData: any, orderId: string) {
        try {
            const response = await axiosClient.patch(`/reservation/order/${orderId}`, { updateData: updateData })
            props.setReservation((prev: IReservation) => {
                return {
                    ...prev,
                    orders: (prev.orders as IOrder[]).map((order) => {
                        if (order._id === response.data._id) {
                            return response.data
                        } else {
                            return order
                        }
                    }),
                }
            })
            toast.success("The order is updated")
        } catch (error) {
            toast.error("Failed to update order")
        }
    }

    function getSelectBg(value: string) {
        switch (value) {
            case EOrderStatus.ACCEPTED:
                return "#92b24d"
            case EOrderStatus.COMPLETE:
                return "#00a66e"
            case EOrderStatus.OPEN:
                return "#fb8b23"
            default:
                return "#fff"
        }
    }

    return (
        <div className="reservation-orders">
            {props.orders.map((order) => {
                return (
                    <Row>
                        <Col>
                            <div className="tile">
                                <div className="section-header">
                                    <span className="orange-icon-container">
                                        <Icon path={mdiBasketOutline} size={1} />
                                    </span>
                                    <h2 className="title">Order</h2>
                                    <p>{moment(order.createdAt).format("YYYY. MM. DD., HH:mm")}</p>
                                </div>
                                <div className="order-infos">
                                    <div className="data-container">
                                        <div className="status-container">
                                            <Select
                                                options={orderStatusOptions}
                                                onChange={(option) => updateOrder({ status: option?.value }, order._id)}
                                                value={{ value: order.status, label: order.status }}
                                                styles={{
                                                    valueContainer: (styles, value) => {
                                                        console.log("VALE", value.getValue())
                                                        const currentValue = value.getValue().length ? value.getValue()[0].value : ""
                                                        return {
                                                            ...styles,
                                                            backgroundColor: getSelectBg(currentValue),
                                                        }
                                                    },
                                                }}
                                            />
                                        </div>
                                        {user.role === ERoles.SUPERADMIN ? (
                                            <div className="action-container">
                                                <div className="action-button" onClick={() => setshowAddServiceModal(true)}>
                                                    <span className="action-name">Add Service</span>
                                                </div>
                                                <div className="action-button" onClick={() => setshowAddProductModal(true)}>
                                                    <span className="action-name">Add Product</span>
                                                </div>
                                                <div className="action-button delete">
                                                    <OverlayTrigger trigger="click" placement="auto" overlay={displayDeletePopover(order)} rootClose>
                                                        <span className="action-name">Delete</span>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <div className="order-images">
                                    <h6 className="images-title">
                                        Order images
                                        <span className="add-icon" onClick={() => setShowOrderImageModal(true)}>
                                            <Icon path={mdiPlus} size={0.7} />
                                        </span>
                                    </h6>

                                    <div className="images">
                                        {order.images.map((image) => {
                                            return (
                                                <div>
                                                    <img className="order-img" src={image} alt="order-img" />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <OrdersTable orderItems={order.order_items} reservationId={props.reservationId} setReservation={props.setReservation} />
                            </div>
                        </Col>
                        <AddServiceModal show={showAddServiceModal} handleClose={() => setshowAddServiceModal(false)} orderid={order._id} setReservation={props.setReservation} />
                        <AddProductModal
                            show={showAddProductModal}
                            handleClose={() => setshowAddProductModal(false)}
                            regionid={props.regionid}
                            orderid={order._id}
                            setReservation={props.setReservation}
                        />
                        <OrderImageUploader show={showOrderImageModal} handleClose={() => setShowOrderImageModal(false)} orderid={order._id} onReady={props.setReservation} />
                    </Row>
                )
            })}
            {user.role === ERoles.SUPERADMIN ? (
                <div className="add-order-container">
                    <span className="orange-icon-container add-order-icon-container" onClick={addOrder}>
                        <Icon path={mdiBasketPlusOutline} size={1} />
                    </span>
                    <span>Add order</span>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default ReservationOrders
