import Icon from "@mdi/react"
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import axiosClient from "../../api/api"
import { ICube, IShopProduct, IStoredProduct } from "../../interfaces/cubes"
import ProductTable from "../products/product-table.component"
import AddShopProduct from "./add-shop-product"
import { mdiPlus } from "@mdi/js"

interface IProps {
    cube: ICube
    setCube: Function
}

function CubeShop(props: IProps) {
    const [shopProducts, setShopProducts] = useState<IStoredProduct[]>([])

    const [showAddProductModal, setShowAddProductModal] = useState(false)

    useEffect(() => {
        fetchShopProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cube._id])

    async function fetchShopProducts() {
        try {
            const response = await axiosClient.get(`/product/shop/${props.cube._id}`)
            const shopProducts = response.data.shopProducts.map((shopProduct: IShopProduct) => {
                return { ...shopProduct.product, quantity: shopProduct.quantity }
            })
            setShopProducts(shopProducts || [])
        } catch (error) {
            toast.error("Failed to get the shop products from the database")
        }
    }

    function countProductsWithoutQuantity() {
        return shopProducts.filter((p) => !p.quantity).length
    }
    return (
        <div className="cube-shop">
            <div className="shop-infos">
                <p>{`Products in the shop: ${shopProducts.length}`}</p>
                {countProductsWithoutQuantity() ? <p>{`(Sold out: ${countProductsWithoutQuantity()})`}</p> : <></>}
                <span className="add-icon" onClick={() => setShowAddProductModal(true)}>
                    <Icon path={mdiPlus} size={0.8} />
                </span>
            </div>
            <AddShopProduct
                shopProductIds={shopProducts.map((p) => p._id)}
                setShopProducts={setShopProducts}
                cubeId={props.cube._id}
                cubeRegion={props.cube.region._id}
                show={showAddProductModal}
                handleClose={() => setShowAddProductModal(false)}
            />
            <ProductTable
                setStoredProducts={setShopProducts}
                products={shopProducts}
                shopProductsCubeId={props.cube._id}
                hiddenColumns={["supplier", "category", "userPrice", "purchasePrice", "supplier", "shortDescription", "enabled", "createdAt"]}
            />
        </div>
    )
}

export default CubeShop
