import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import axiosClient from "../../api/api"
import { IRegion, IWarehouse } from "../../interfaces/cubes"
import Loader from "../../components/loader.component"
import { Row, Col, Form, Button } from "react-bootstrap"
import ProductTable from "../products/product-table.component"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import AddWarehouseProduct from "./add-product-warehouse.component"
import { FormikValues, useFormik } from "formik"
import * as Yup from "yup"
import { AxiosError } from "axios"
interface IProps {
    region: IRegion
    setRegion: Function
}

function Warehouse(props: IProps) {
    const [warehouse, setWarehouse] = useState<IWarehouse>()
    const [loading, setLoading] = useState<boolean>(false)

    const [showAddProductModal, setShowAddProductModal] = useState(false)

    useEffect(() => {
        fetchWarehouse()

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchWarehouse() {
        setLoading(true)
        try {
            if (props.region.warehouse) {
                const response = await axiosClient.get(`/warehouse/${props.region.warehouse}`)

                const warehouse = response.data.warehouse
                setWarehouse(warehouse)
            }
        } catch (error) {
            toast.error("Failed to fetch the warehouse from the server")
        }
        setLoading(false)
    }

    function getProductsOfWarehouse() {
        if (!warehouse) {
            return []
        }

        return warehouse.products.map((storedProduct) => {
            return { ...storedProduct.product, quantity: storedProduct.quantity }
        })
    }

    function countProductsWithoutQuantity() {
        return warehouse?.products.filter((p) => !p.quantity).length
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
        }),
        onSubmit: async (values: FormikValues, actions: any) => {
            try {
                const warehouseData = {
                    name: values.name,
                    description: values.description,
                    regionId: props.region._id,
                }
                const response = await axiosClient.post("/warehouse", warehouseData)
                actions.resetForm()
                toast.success("Warehouse is created")
                props.setRegion((prev: IRegion) => {
                    return { ...prev, warehouse: response.data._id }
                })
                fetchWarehouse()
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <div className="warehouse">
            {props.region.warehouse ? (
                <>
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <Row>
                                <Col>
                                    <div className="tile">
                                        <h4>{warehouse?.name}</h4>
                                        <p>{warehouse?.description}</p>
                                    </div>
                                </Col>
                            </Row>

                            <div className="warehouse-infos">
                                <p>{`Products in the warehouse: ${warehouse?.products.length}`}</p>
                                {countProductsWithoutQuantity() ? <p>{`(Sold out: ${countProductsWithoutQuantity()})`}</p> : <></>}
                                <span className="add-icon" onClick={() => setShowAddProductModal(true)}>
                                    <Icon path={mdiPlus} size={0.8} />
                                </span>
                            </div>

                            {warehouse ? (
                                <AddWarehouseProduct
                                    currentProductIds={warehouse?.products.map((p) => p.product._id) || []}
                                    setWarehouse={setWarehouse}
                                    warehouseId={warehouse?._id}
                                    region={(warehouse.region as IRegion)._id}
                                    show={showAddProductModal}
                                    handleClose={() => setShowAddProductModal(false)}
                                />
                            ) : (
                                <></>
                            )}

                            <div>
                                <ProductTable
                                    setStoredProducts={setWarehouse}
                                    products={getProductsOfWarehouse()}
                                    warehouseId={warehouse?._id}
                                    hiddenColumns={["supplier", "category", "userPrice", "purchasePrice", "supplier", "shortDescription", "enabled", "createdAt"]}
                                />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <Row>
                    <Col>
                        <div className="add-warehouse-container">
                            <p>Add warehouse to the region</p>

                            <Form onSubmit={formik.handleSubmit}>
                                <Form.Group className="mb-3" controlId="formSupplier">
                                    <Form.Label>Name:</Form.Label>
                                    <Form.Control onChange={formik.handleChange} name="name" onBlur={formik.handleBlur} value={formik.values.name} type="text" placeholder="Name..." />
                                    {formik.touched.name && formik.errors.name ? <p className="input-error">{formik.errors.name}</p> : null}
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formSupplier">
                                    <Form.Label>Description:</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        onChange={formik.handleChange}
                                        name="description"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                        type="text"
                                        placeholder="Description..."
                                    />
                                    {formik.touched.description && formik.errors.description ? <p className="input-error">{formik.errors.description}</p> : null}
                                </Form.Group>

                                <Button className="orange" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default Warehouse
