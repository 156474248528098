import ProductDetails from "../../components/products/product-details.component"
import ProductUpdate from "../../components/products/product-update.component"
import { ERoles } from "../../constants/enum"
import { ISubPageRoute } from "../../interfaces/pages"

const productDetailsRoutes: ISubPageRoute[] = [
    {
        path: "/admin/products/:id",
        name: "Details",
        component: ProductDetails,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/products/:id/update",
        name: "Edit",
        component: ProductUpdate,
        permission: [ERoles.SUPERADMIN],
    },
]

export default productDetailsRoutes
