import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import axiosClient from "../../api/api"
import { IMainCategory } from "../../interfaces/cubes"
import Loader from "../loader.component"
import CategoryTable from "./category-table.component"

function Categories() {
    const history = useHistory()

    const [categories, setCategories] = useState([])

    const [isLoading, setisLoading] = useState(true)

    useEffect(() => {
        // fetch categories
        fetchCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchCategories() {
        setisLoading(true)

        try {
            const response = await axiosClient.get(`/category`)
            setCategories(response.data)
        } catch (error) {
            console.log("Failed to fetch cubes")
            history.push("/admin/404")
        }
        setisLoading(false)
    }
    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <CategoryTable hiddenColumns={["createdAt"]} categories={categories} setCategories={setCategories} />
                </div>
            )}
        </div>
    )
}

export default Categories
