import { ISubPageRoute } from "../../interfaces/pages"
import ProductTable from "../../components/products/product-table.component"
import CreateProduct from "../../components/suppliers/create-product.component"
import Categories from "../../components/products/categories.component"

const productsMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/products/main",
        name: "Infos",
        component: ProductTable,
        props: {
            hiddenColumns: ["category", "shortDescription", "createdAt", "purchasePrice"],
        },
    },
    {
        path: "/admin/products/main/create",
        name: "Create Product",
        component: CreateProduct,
    },
    {
        path: "/admin/products/main/categories",
        name: "Categories",
        component: Categories,
    },
]

export default productsMainRoutes
