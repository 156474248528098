import { useMemo, useState } from "react"
import { Button } from "react-bootstrap"
import Select from "react-select"
import axiosClient from "../../api/api"
import { IOrderItem, IProduct, IReservation, IOrder } from "../../interfaces/cubes"
import TableDisplayer from "../table.component"
import OrderUnitAction from "./order-unit-action.component"
import Icon from "@mdi/react"
import { mdiTune, mdiCheck, mdiClose } from "@mdi/js"
import { textFilter } from "../column-filter.component"
import { toast } from "react-toastify"

interface IProps {
    orderItems: IOrderItem[]
    reservationId: string
    setReservation: Function
}

enum OrderStatus {
    OPEN = "open",
    INCOMING = "incoming",
    PREPARED = "prepared",
    COMPLETE = "complete",
}

interface IOrderStatusOption {
    value: OrderStatus
    label: OrderStatus
}
const unitStatusOptions: IOrderStatusOption[] = [
    { value: OrderStatus.OPEN, label: OrderStatus.OPEN },
    { value: OrderStatus.INCOMING, label: OrderStatus.INCOMING },
    { value: OrderStatus.PREPARED, label: OrderStatus.PREPARED },
    { value: OrderStatus.COMPLETE, label: OrderStatus.COMPLETE },
]

function OrdersTable(props: IProps) {
    const [show, setShow] = useState(false)
    const [selectedOrderItem, setSelectedOrderItem] = useState<IOrderItem>()

    async function updateOrderUnit(unitId: string, newData: any) {
        try {
            const response = await axiosClient.patch(`/reservation/orderitem/${unitId}`, { updateData: newData })
            props.setReservation((prev: IReservation) => {
                return {
                    ...prev,
                    orders: (prev.orders as IOrder[]).map((order) => {
                        if (order._id === response.data._id) {
                            return response.data
                        } else {
                            return order
                        }
                    }),
                }
            })
            toast.success("The order unit is updated")
        } catch (error) {
            toast.error("Failed to update order")
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: "Details",
                Cell: (data: any) => {
                    return (
                        <Button
                            className="orange"
                            onClick={() => {
                                setSelectedOrderItem(data.row.original)
                                setShow(true)
                            }}>
                            <Icon path={mdiTune} size={1} />
                        </Button>
                    )
                },
                disableFilters: true,
            },
            {
                Header: "Type",
                accessor: (data: any) => {
                    return data.type === "ServiceOrderItem" ? "Service" : "Product"
                },
                Filter: textFilter,
            },
            {
                Header: "Product",
                accessor: (data: any) => {
                    return data.type === "ServiceOrderItem" ? data.name : (data.product as IProduct).name
                },
                Filter: textFilter,
            },

            {
                Header: "Status",
                accessor: "status",
                Cell: (data: any) => {
                    return (
                        <Select
                            // Fixes the overlapping problem of the component
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            options={unitStatusOptions}
                            onChange={(option) => updateOrderUnit(data.row.original._id, { status: option?.value })}
                            value={{ value: data.row.original.status, label: data.row.original.status }}
                        />
                    )
                },
                Filter: textFilter,
            },
            {
                Header: "Price",
                accessor: "price",
                Cell: (data: any) => {
                    return <span>{`${data.value} Ft`}</span>
                },
                disableFilters: true,
            },
            {
                Header: "Payment status",
                accessor: "isPayed",
                Cell: (data: any) => {
                    return data.value ? <Icon path={mdiCheck} size={1} /> : <Icon path={mdiClose} size={1} />
                },
                disableFilters: true,
            },
            {
                Header: "Quantity",
                accessor: "quantity",
                Cell: (data: any) => {
                    return <span>{data.value ? data.value : "-"}</span>
                },
                disableFilters: true,
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return (
        <div>
            {props.orderItems && <TableDisplayer columns={columns} data={props.orderItems} />}
            {selectedOrderItem && <OrderUnitAction setReservation={props.setReservation} orderItem={selectedOrderItem} show={show} handleClose={() => setShow(false)} />}
        </div>
    )
}

export default OrdersTable
