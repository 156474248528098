//  Modules from the React eco-system
import React, { useState } from "react"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// Interfaces, enums
import { IMainCategory } from "../../interfaces/cubes"

// style, bootstrap, icons
import { Modal, Button, Form } from "react-bootstrap"

interface IProps {
    show: boolean
    handleClose: any
    onCategoryCreation?: Function
}

const FormInitialValues = {
    name: "",
    secondName: "",
    description: "",
}

const ImageFormInitialValues = {
    image: undefined,
}

function CreateCategory(props: IProps) {
    const [createdCategory, setCreatedCategory] = useState<IMainCategory | null>()

    const formik = useFormik({
        initialValues: FormInitialValues,
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            secondName: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const submitBody = {
                    name: values.name,
                    second_name: values.secondName,
                    description: values.description,
                }
                const response = await axiosClient.post("/category", submitBody)
                setCreatedCategory(response.data)
                props.onCategoryCreation && props.onCategoryCreation()
                resetForm()
                toast.success("Category is created")
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    const imageFormik = useFormik({
        initialValues: ImageFormInitialValues,
        validationSchema: Yup.object({
            image: Yup.mixed().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (values.image && createdCategory) {
                    const formData = new FormData()
                    formData.append("type", "category")
                    formData.append("image", values.image)

                    await axiosClient.patch(`/category/${createdCategory._id}/image`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    props.handleClose()
                    setCreatedCategory(null)

                    toast.success("Image is uploaded for the category")
                    resetForm({
                        values: ImageFormInitialValues,
                    })
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <>
            <Modal
                className="create-category"
                show={props.show}
                onHide={() => {
                    props.handleClose()
                    formik.resetForm()
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {createdCategory ? (
                        <div>
                            <p className="category-success">Category is created</p>
                            <p className="category-created-name">{createdCategory.name}</p>
                            <p className="category-created-description">{createdCategory.description}</p>
                            {imageFormik.values.image && <img className="preview-image" src={URL.createObjectURL(imageFormik.values.image)} alt="uploaded" />}

                            <Form onSubmit={imageFormik.handleSubmit}>
                                <Form.Group className="mb-3" controlId="formCategoryImage">
                                    <Form.Label>Image:</Form.Label>
                                    <Form.Control
                                        onChange={(evt) => {
                                            imageFormik.setFieldValue("image", (evt.target as any).files[0])
                                        }}
                                        name="image"
                                        type="file"
                                    />
                                    {imageFormik.touched.image && imageFormik.errors.image ? <div className="input-error">{imageFormik.errors.image}</div> : null}
                                </Form.Group>

                                <Button className="orange" type="submit">
                                    Save
                                </Button>
                            </Form>
                        </div>
                    ) : (
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group className="mb-3" controlId="formCategory">
                                <Form.Label>Name</Form.Label>
                                <Form.Control onChange={formik.handleChange} name="name" onBlur={formik.handleBlur} value={formik.values.name} type="text" placeholder="Name of the category..." />
                                {formik.touched.name && formik.errors.name ? <p className="input-error">{formik.errors.name}</p> : null}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formCategory">
                                <Form.Label>Second Name</Form.Label>
                                <Form.Control
                                    onChange={formik.handleChange}
                                    name="secondName"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.secondName}
                                    type="text"
                                    placeholder="Second name of the category..."
                                />
                                {formik.touched.secondName && formik.errors.secondName ? <p className="input-error">{formik.errors.secondName}</p> : null}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formCategory">
                                <Form.Label>Long Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    onChange={formik.handleChange}
                                    name="description"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                    type="text"
                                    placeholder="Description of the category..."
                                />
                                {formik.touched.description && formik.errors.description ? <p className="input-error">{formik.errors.description}</p> : null}
                            </Form.Group>

                            <Button className="orange" type="submit">
                                Upload
                            </Button>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateCategory
