// Own components
import CreateRegion from "../../components/regions/regions-create-form.component"
import RegionsTable from "../../components/regions/regions-table.component"

// Interfaces, enums
import { ISubPageRoute } from "../../interfaces/pages"
import { ERoles } from "../../constants/enum"

const regionsMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/regions/main",
        name: "Infos",
        component: RegionsTable,
        props: {
            hiddenColumns: ["id", "createdAt"],
        },
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/regions/main/update",
        name: "Create Region",
        component: CreateRegion,
        permission: [ERoles.SUPERADMIN],
    },
]

export default regionsMainRoutes
