//  Modules from the React eco-system
import React from "react"

// Screens
import Cubes from "../pages/cubes.page"
import Dashboard from "../pages/dashboard.page"
import Regions from "../pages/regions.page"
import Users from "../pages/users.page"
import RegionDetails from "../pages/region-details.page"
import CubeDetails from "../pages/cube-details.page"
import Suppliers from "../pages/suppliers.page"
import SupplierDetails from "../pages/supplier-details.page"
import Reservations from "../pages/reservations.page"
import ReservationDetails from "../pages/reservation-details.page"
import UserDetails from "../pages/user-details.page"
import Operators from "../pages/operators.page"
import OperatorDetails from "../pages/operators-details.page"
import SuperadminPage from "../pages/superadmins.page"
import LoggedInUserPage from "../pages/profile.page"
import ProductsPage from "../pages/products.page"
import ProductDetailsPage from "../pages/product-details.page"
import ProductOrdersPage from "../pages/product-orders.page"

// Interfaces, enums
import { ERoles } from "../constants/enum"

// Styles, bootstrap, icons
import {
    mdiChartDonut,
    mdiAccountStarOutline,
    mdiCubeOutline,
    mdiFoodOutline,
    mdiMapLegend,
    mdiAccountMultipleOutline,
    mdiCalendarBlankOutline,
    mdiTruckDeliveryOutline,
    mdiWrenchOutline,
    mdiCartOutline,
} from "@mdi/js"
import NotFoundPage from "../pages/not-found.page"

export interface IDashboardRoute {
    path: string
    name: string
    exact: boolean
    icon: string // for the sidebar
    component: React.FunctionComponent
    displayOnSidebar: boolean // if false: it does not displayed on the sidebar
    permission: ERoles[] // for the filtering by the logged in user
}

const dashboardRoutes: IDashboardRoute[] = [
    {
        path: "/admin/dashboard",
        name: "Dashboard",
        exact: true,
        icon: mdiChartDonut,
        component: Dashboard,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/regions/main/:page?",
        name: "Regions",
        exact: true,
        icon: mdiMapLegend,
        component: Regions,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/regions/:id/:page?",
        name: "Regions",
        exact: true,
        icon: mdiMapLegend,
        component: RegionDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/cubes/main/:page?",
        name: "Cubes",
        exact: true,
        icon: mdiCubeOutline,
        component: Cubes,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/cubes/:id/:page?",
        name: "Cubes",
        exact: true,
        icon: mdiCubeOutline,
        component: CubeDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/reservations",
        name: "Reservations",
        exact: true,
        icon: mdiCalendarBlankOutline,
        component: Reservations,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },
    {
        path: "/admin/reservations/:id",
        name: "Reservations",
        exact: true,
        icon: mdiCalendarBlankOutline,
        component: ReservationDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },
    {
        path: "/admin/products/main/:page?",
        name: "Products",
        exact: true,
        icon: mdiFoodOutline,
        component: ProductsPage,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/products/:id/:page?",
        name: "Products",
        exact: true,
        icon: mdiFoodOutline,
        component: ProductDetailsPage,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/operators/main/:page?",
        name: "Operators",
        exact: true,
        icon: mdiWrenchOutline,
        component: Operators,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/operators/:id/:page?",
        name: "Operators",
        exact: true,
        icon: mdiWrenchOutline,
        component: OperatorDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/suppliers/main/:page?",
        name: "Suppliers",
        exact: true,
        icon: mdiTruckDeliveryOutline,
        component: Suppliers,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },
    {
        path: "/admin/suppliers/:id/:page?",
        name: "Suppliers",
        exact: true,
        icon: mdiTruckDeliveryOutline,
        component: SupplierDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },
    {
        path: "/admin/users/main/:page?",
        name: "Users",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: Users,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },
    {
        path: "/admin/users/:id/:page?",
        name: "Users",
        exact: true,
        icon: mdiAccountMultipleOutline,
        component: UserDetails,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },
    {
        path: "/admin/superadmins/:page?",
        name: "Administrators",
        exact: true,
        icon: mdiAccountStarOutline,
        component: SuperadminPage,
        displayOnSidebar: true,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/profile",
        name: "Own profile",
        exact: true,
        icon: mdiAccountStarOutline,
        component: LoggedInUserPage,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/404",
        name: "Not Found",
        exact: true,
        icon: mdiAccountStarOutline,
        component: NotFoundPage,
        displayOnSidebar: false,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/orders",
        name: "Orders",
        exact: true,
        icon: mdiCartOutline,
        component: ProductOrdersPage,
        displayOnSidebar: true,
        permission: [ERoles.SUPPLIER],
    },
]

export default dashboardRoutes
