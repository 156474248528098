//  Modules from the React eco-system
import React, { useMemo, useState } from "react"
import { Link } from "react-router-dom"

// Third-party modules
import moment from "moment"
import { toast } from "react-toastify"

// Axios
import axiosClient from "../../api/api"
import axios, { AxiosError } from "axios"

// Own components
import TableDisplayer from "../table.component"
import { dateFilter, selectColumnFilter, textFilter } from "../column-filter.component"

// interfaces, enums
import { IProduct, IShopProduct, IStoredProduct } from "../../interfaces/cubes"

// bootstrap, icons
import Icon from "@mdi/react"
import { mdiTune, mdiFoodOutline, mdiCheck, mdiClose, mdiPencil, mdiMinus, mdiPlus } from "@mdi/js"

interface IProps {
    products: IProduct[] | IStoredProduct[]
    hiddenColumns?: string[]
    shopProductsCubeId?: string
    warehouseId?: string
    setStoredProducts?: Function
}

function ProductTable(props: IProps) {
    const [selectedRow, setSelectedRow] = useState<string>("")

    const [quantityForUpdate, setQuantityForUpdate] = useState<number>(0)

    const columns = useMemo(() => {
        const result = [
            {
                Header: () => null, // No header
                id: "details", // It needs an ID
                Cell: (data: any) => {
                    return (
                        <div>
                            <Link to={`/admin/products/${data.row.original._id}`}>
                                <span className="icon-container btn orange">
                                    <Icon path={mdiTune} size={1} />
                                </span>{" "}
                            </Link>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: "Image",
                accessor: "image",
                Cell: (data: any) => {
                    return (
                        <div>
                            {data.value ? (
                                <img className="table-image" src={data.value} alt="" />
                            ) : (
                                <div className="table-icon-container">
                                    <Icon path={mdiFoodOutline} size={1} />
                                </div>
                            )}
                        </div>
                    )
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("image"),
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("name"),
            },
            {
                Header: "Category (unused column)",
                accessor: "category",
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("category"),
            },
            {
                Header: "Main Category",
                accessor: (data: IProduct): string => {
                    return data.main_category?.name || "-"
                },
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("main_category"),
            },
            {
                Header: "Id",
                accessor: "_id",
                Filter: textFilter,
                isHidden: true,
            },
            {
                Header: "User price",
                accessor: "user_price",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("userPrice"),
            },

            {
                Header: "Purchase price",
                accessor: "purchase_price",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("purchasePrice"),
            },
            {
                Header: "Supplier",
                accessor: (data: IProduct): string => {
                    return `${data.supplier.firstname} ${data.supplier.lastname}`
                },
                Cell: (data: any) => {
                    return <Link to={`/admin/suppliers/${data.row.original.supplier._id}`}>{data.value}</Link>
                },
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("supplier"),
            },
            {
                Header: "Short description",
                accessor: "short_description",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("shortDescription"),
            },
            {
                Header: "Enabled",
                accessor: "enabled",
                Cell: (data: any) => {
                    return data.value ? <Icon path={mdiCheck} size={1} /> : <Icon path={mdiClose} size={1} />
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("enabled"),
            },
            {
                Header: "Created at",
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("createdAt"),
            },
        ]

        // column is rendered if the products are shop products
        if (props.shopProductsCubeId || props.warehouseId) {
            result.push({
                Header: props.shopProductsCubeId ? "Quantity in the Qube" : "Quantity in the warehouse",
                accessor: "quantity",
                Cell: (data: any) => {
                    return data.row.values._id === selectedRow ? (
                        <div className="quantity-container">
                            <div className="quantity-input-container">
                                <span className="quantity-set" onClick={() => setQuantityForUpdate(quantityForUpdate - 1)}>
                                    <Icon path={mdiMinus} size={0.8} />
                                </span>
                                <input className="quantity" name="quantity" value={quantityForUpdate} onChange={(e) => setQuantityForUpdate(Number(e.target.value))} type="number" />
                                <span className="quantity-set" onClick={() => setQuantityForUpdate(quantityForUpdate + 1)}>
                                    <Icon path={mdiPlus} size={0.8} />
                                </span>
                            </div>
                            <span className="close action" onClick={() => setSelectedRow("")}>
                                <Icon path={mdiClose} size={0.8} />
                            </span>
                            <span className="add action" onClick={(e) => updateQuantity()}>
                                <Icon path={mdiCheck} size={0.8} />
                            </span>
                        </div>
                    ) : (
                        <div className="quantity-container">
                            <span>{data.value}</span>
                            <span
                                className="action edit"
                                onClick={() => {
                                    setSelectedRow(data.row.values._id)
                                    setQuantityForUpdate(data.row.values.quantity)
                                }}>
                                <Icon path={mdiPencil} size={0.7} />
                            </span>
                        </div>
                    )
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("quantity"),
            })
        }

        return result
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.hiddenColumns, selectedRow, quantityForUpdate, props.shopProductsCubeId])

    async function updateQuantity() {
        try {
            const url = props.shopProductsCubeId ? `/cube/${props.shopProductsCubeId}/shop` : `/warehouse/${props.warehouseId}/product`
            const response = await axiosClient.patch(url, {
                productId: selectedRow,
                quantity: quantityForUpdate,
            })

            if (props.shopProductsCubeId) {
                const shopProducts = response.data.shopProducts.map((shopProduct: IShopProduct) => {
                    return { ...shopProduct.product, quantity: shopProduct.quantity }
                })
                props.setStoredProducts && props.setStoredProducts(shopProducts || [])
                toast.success("Shop product is updated")
            } else {
                const warehouse = response.data.warehouse
                props.setStoredProducts && props.setStoredProducts(warehouse || undefined)
                toast.success("Warehouse product is updated")
            }

            setSelectedRow("0")
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Failed to update quantity")
            }
        }
    }

    return <div className="product-table">{props.products && <TableDisplayer columns={columns} data={props.products} />}</div>
}

export default ProductTable
