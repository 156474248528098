import { Chart as ChartJS, ChartData, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from "chart.js"
import { Radar } from "react-chartjs-2"

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

interface IProps {
    data: ChartData<any>
}

function RadarChart(props: IProps) {
    return <Radar data={props.data} options={{ maintainAspectRatio: false }} />
}

export default RadarChart
