//  Modules from the React eco-system
import React, { useState, useEffect, useContext } from "react"
import { Route, Switch, useHistory } from "react-router-dom"

// redux, store
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"
import { UserContext } from "../store/UserProvider"

// axios
import axiosClient from "../api/api"

// Own components
import Loader from "../components/loader.component"
import SubPageNavbar from "../components/navigation/subpage-navbar.component"

// interfaces, enums
import { IProduct } from "../interfaces/cubes"
import { ERoles } from "../constants/enum"
import { ISubPageRoute } from "../interfaces/pages"

import productsMainRoutes from "../routes/mains/products-main.route"

function ProductsPage() {
    //Logged in user
    const { user } = useContext(UserContext)

    const dispatch = useDispatch()
    const history = useHistory()

    // products from the fetch
    const [products, setProducts] = useState<IProduct[]>([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // set page name in redux for the navbar
        dispatch(setPageName({ pageName: "Products", pageUrl: "/admin/products", subPageName: "" }))
        fetchProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * fetch products from the backend
     */
    async function fetchProducts() {
        setIsLoading(true)
        try {
            const response = await axiosClient.get(`/product${user.role === ERoles.SUPPLIER ? "?supplier=" + user._id : ""}`)
            setProducts(response.data)
        } catch (error) {
            console.log("Failed to fetch products")
            history.push("/admin/404")
        }
        setIsLoading(false)
    }

    return (
        <div className="products-page">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <SubPageNavbar routes={productsMainRoutes} />

                    <Switch>
                        {productsMainRoutes.map((route: ISubPageRoute, key: number) => {
                            return (
                                <Route
                                    key={key}
                                    path={route.path}
                                    exact
                                    render={() => {
                                        return (
                                            <div>
                                                <route.component products={products} setProducts={setProducts} {...route.props} />
                                            </div>
                                        )
                                    }}
                                />
                            )
                        })}
                    </Switch>
                </>
            )}
        </div>
    )
}

export default ProductsPage
