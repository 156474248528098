//  Modules from the React eco-system
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

// Own components
import ReservationsTable from "../components/reservations/reservation-table.component"
import Loader from "../components/loader.component"

// Axios
import axiosClient from "../api/api"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

// Interfaces, enums
import { IReservation } from "../interfaces/cubes"

function Reservations() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [reservations, setReservations] = useState<IReservation[]>([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(setPageName({ pageName: "Reservations", pageUrl: "/admin/reservations", subPageName: "" }))

        fetchReservations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchReservations() {
        setIsLoading(true)
        try {
            const response = await axiosClient.get("/reservation")
            setReservations(response.data)
        } catch (error) {
            console.log("Failed to fetch reservations")
            history.push("/admin/404")
        }
        setIsLoading(false)
    }

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="table-tile">
                        <ReservationsTable reservations={reservations} hiddenColumns={["cube", "endDate", "id", "status", "createdAt"]} />
                    </div>
                </>
            )}
        </div>
    )
}

export default Reservations
