//  Modules from the React eco-system
import React, { useState, useEffect, useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import { useParams } from "react-router"

// Third-party modules
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import { toast } from "react-toastify"
import moment from "moment"

// Own components
import ReservationMainInfos from "../components/reservations/reservation-maininfos.component"
import UserInfo from "../components/users/user-info.component"
import CubeInfos from "../components/cubes/cube-maininfos.component"
import ReservationOrders from "../components/reservations/reservation-orders.component"
import CustomGoogleMap from "../components/google-map.component"
import PaymentIntentsTable from "../components/reservations/payment-intents-table.component"

// Axios
import axiosClient from "../api/api"
import axios, { AxiosError } from "axios"

// Redux, store
import { useDispatch } from "react-redux"
import { setPageName } from "../store/pageSlice"
import { UserContext } from "../store/UserProvider"

// Interfaces, enums
import { IReservation, ICube, IOrderItem } from "../interfaces/cubes"
import { IUser } from "../interfaces/persons"
import { EOrderItemStatus, ERoles } from "../constants/enum"

// Styles, bootstrap, icons
import { Row, Col, Popover, Button, OverlayTrigger } from "react-bootstrap"
import Loader from "../components/loader.component"
import { mdiLockClock, mdiWaterPump, mdiCreditCardOutline } from "@mdi/js"
import Icon from "@mdi/react"

ChartJS.register(ArcElement, Tooltip, Legend)

interface RouteParams {
    id: string
}

/**
 * page of one reservation
 */
function ReservationDetails() {
    //Logged in user
    const { user } = useContext(UserContext)

    const dispatch = useDispatch()
    const params = useParams<RouteParams>()
    const history = useHistory()

    // reservation from the fetch
    const [reservation, setReservation] = useState<IReservation | null>(null)
    // payment intents
    const [paymentIntents, setPaymentIntents] = useState<any[]>([])

    useEffect(() => {
        // set page name
        dispatch(setPageName({ pageName: "Reservations", pageUrl: "/admin/reservations", subPageName: reservation ? reservation.increment_id : "" }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservation])

    useEffect(() => {
        // fetch regions
        fetchReservation()
        fetchPaymentIntents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * fetch the reservation by the id from the params
     */
    async function fetchReservation() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/reservation/${params.id}`)
                const reservation: IReservation = response.data
                setReservation(reservation)
            }
        } catch (error) {
            toast.error("Failed to fetch reservation")
            history.push("/admin/404")
        }
    }

    /**
     * fetch the payment intents by the id from the params
     */
    async function fetchPaymentIntents() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/reservation/payments/${params.id}`)
                setPaymentIntents(response.data.data || [])
            }
        } catch (error) {
            toast.error("Failed to fetch payment intents")
        }
    }

    function getMarkerData() {
        if (reservation) {
            return [
                {
                    position: { lat: (reservation.cube as ICube).location.coordinates[0], lng: (reservation.cube as ICube).location.coordinates[1] },
                    infoWindow: (
                        <div className="infoWindow">
                            <Link to={`/admin/cubes/${(reservation.cube as ICube)._id}`}>{(reservation.cube as ICube).name}</Link>
                        </div>
                    ),
                },
            ]
        } else {
            return []
        }
    }

    /**
     * create data for doughnot chart
     * @returns ChartData
     */
    function createOrderItemChartData(): ChartData<any> {
        let data = {
            [EOrderItemStatus.OPEN]: 0,
            [EOrderItemStatus.INCOMING]: 0,
            [EOrderItemStatus.PREPARED]: 0,
            [EOrderItemStatus.COMPLETE]: 0,
        }

        const orderItems = reservation?.orders.reduce((a, { order_items }) => a.concat(order_items), [] as IOrderItem[])

        orderItems && orderItems.forEach((item) => data[item.status]++)

        return {
            labels: [EOrderItemStatus.OPEN, EOrderItemStatus.INCOMING, EOrderItemStatus.PREPARED, EOrderItemStatus.COMPLETE],
            datasets: [
                {
                    data: [data[EOrderItemStatus.OPEN], data[EOrderItemStatus.INCOMING], data[EOrderItemStatus.PREPARED], data[EOrderItemStatus.COMPLETE]],
                    backgroundColor: ["#cd212a", "#f2552c", "#92b24d", "#00a66e"],
                },
            ],
        }
    }

    /**
     * popover for the delete button
     */
    const deletePopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Delete reservation</Popover.Header>
            <Popover.Body>
                <div>
                    <p>Delete this reservation?</p>
                    <Button className="btn orange" onClick={deleteReservation}>
                        Delete
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    )

    /**
     * delete reservation from the backend
     */
    async function deleteReservation() {
        try {
            if (reservation) {
                await axiosClient.delete(`/reservation/${reservation._id}`)
                toast.success("Reservation is deleted")
                history.push("/admin/reservations")
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Failed to delete reservation")
            }
        }
    }

    return (
        <div className="reservation-details-page">
            {reservation ? (
                <div>
                    <Row>
                        <Col xs={12} md={6}>
                            <ReservationMainInfos reservation={reservation} setReservation={setReservation} />
                        </Col>

                        <Col xs={12} md={6}>
                            <div className="simple-tile map-tile">
                                <CustomGoogleMap
                                    zoom={7}
                                    center={{ lat: (reservation.cube as ICube).location.coordinates[0], lng: (reservation.cube as ICube).location.coordinates[1] }}
                                    // markerPositions={[{ lat: (reservation.cube as ICube).location.coordinates[0], lng: (reservation.cube as ICube).location.coordinates[1] }]}
                                    markerDatas={getMarkerData()}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <UserInfo user={reservation.user as IUser} />
                        </Col>
                        <Col>
                            <CubeInfos cube={reservation.cube as ICube} />
                        </Col>
                        <Col>
                            <div className="tile doughnot-chart-container">
                                <h2>Order items</h2>

                                <div className="doughnot-chart">
                                    <Doughnut data={createOrderItemChartData()} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col>
                            <div className="tile home-assistant-section">
                                <h2>Home Assistant</h2>
                                <Row className="home-assistant-boxes">
                                    <Col xs={12} sm={4} className="lock-box box">
                                        <Icon className="box-icon" path={mdiLockClock} size={3} />

                                        <p>{`First open: ${reservation.firstOpen ? moment(reservation.firstOpen).format("DD. MMMM. YYYY., HH:mm") : "-"}`}</p>
                                        <p>{`Last close: ${reservation.lastClose ? moment(reservation.lastClose).format("DD. MMMM. YYYY., HH:mm") : "-"}`}</p>
                                    </Col>
                                    <Col xs={12} sm={4} className="water-box box">
                                        <Icon className="box-icon" path={mdiWaterPump} size={3} />
                                        <p>{`Water level at the first open: ${reservation.waterPctStart ? reservation.waterPctStart + "%" : "-"}`}</p>
                                        <p>{`Water level at the last close: ${reservation.waterPctLastClose ? reservation.waterPctLastClose + "%" : "-"}`}</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ReservationOrders regionid={reservation.region} orders={reservation.orders} setReservation={setReservation} reservationId={reservation._id} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="tile payments-section">
                                <div className="section-header">
                                    <span className="orange-icon-container">
                                        <Icon path={mdiCreditCardOutline} size={1} />
                                    </span>
                                    <h2 className="title">Payment Intents</h2>
                                </div>

                                <PaymentIntentsTable paymentIntents={paymentIntents} />
                            </div>
                        </Col>
                    </Row>
                    {user.role === ERoles.SUPERADMIN ? (
                        <Row>
                            <Col>
                                <OverlayTrigger trigger="click" placement="auto" overlay={deletePopover} rootClose>
                                    <Button className="orange">Delete Reservation</Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default ReservationDetails
