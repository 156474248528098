export enum ERoles {
    SUPERADMIN = "SuperAdmin",
    OPERATOR = "Operator",
    SUPPLIER = "Supplier",
}

export enum EReservationStatus {
    PAYMENT_REJECTED = "payment rejected",
    OPEN = "open",
    CONFIRMED = "confirmed",
    READY = "ready",
    STARTED = "started",
    COMPLETE = "complete",
}

export enum EOrderStatus {
    OPEN = "open",
    ACCEPTED = "accepted",
    COMPLETE = "complete",
}

export enum EOrderItemStatus {
    OPEN = "open",
    INCOMING = "incoming",
    PREPARED = "prepared",
    COMPLETE = "complete",
}

export enum EOrderItemType {
    SERVICE_ORDER_ITEM = "ServiceOrderItem",
    PRODUCT_ORDER_ITEM = "ProductOrderItem",
}

export enum EActivityAction {
    POST = "post",
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    LOGIN = "login",
}
