//  Modules from the React eco-system
import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import Cookies from "universal-cookie"
import { toast } from "react-toastify"

// Axios
import axiosClient from "../api/api"
import axios, { AxiosError } from "axios"

// Interfaces, enums

// Styles, bootstrap, icons
import Icon from "@mdi/react"
import { mdiLogin } from "@mdi/js"
import { Button, Form } from "react-bootstrap"

// images
import { ReactComponent as Logo } from "../assets/img/BBQBE_logo_vertical_flame.svg"
import { IBaseAdmin } from "../interfaces/persons"

interface RouteParams {
    token: string
}

function VerificationPage() {
    const params = useParams<RouteParams>()

    const [admin, setAdmin] = useState<IBaseAdmin>()

    const formik = useFormik({
        initialValues: {
            password: "",
            passwordAgain: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Password is a required field"),
            passwordAgain: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
        }),
        onSubmit: async (values) => {
            try {
                const response = await axiosClient.post(`/auth/verify/${params.token}`, { password: values.password, passwordAgain: values.passwordAgain })
                const admin: IBaseAdmin = response.data
                setAdmin(admin)
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    const err = error as AxiosError
                    toast.error(err.response?.data)
                } else {
                    toast.error("Failed to verify the account")
                }
            }
        },
    })

    return (
        <div className="verification-page">
            <div className="verification-container">
                <div className="logo-container">
                    <Logo />
                </div>
                {!admin ? (
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="form-group" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control onChange={formik.handleChange} name="password" onBlur={formik.handleBlur} value={formik.values.password} type={"password"} placeholder="Password" />

                            {formik.touched.password && formik.errors.password ? <Form.Text className="input-error">{formik.errors.password}</Form.Text> : null}
                        </Form.Group>
                        <Form.Group className="form-group" controlId="formBasicPassword">
                            <Form.Label>Password Again</Form.Label>
                            <Form.Control
                                onChange={formik.handleChange}
                                name="passwordAgain"
                                onBlur={formik.handleBlur}
                                value={formik.values.passwordAgain}
                                type={"password"}
                                placeholder="Password Again"
                            />

                            {formik.touched.passwordAgain && formik.errors.passwordAgain ? <Form.Text className="input-error">{formik.errors.passwordAgain}</Form.Text> : null}
                        </Form.Group>
                        <Button variant="primary" type="submit" className="login-button orange">
                            Verify Account
                        </Button>
                    </Form>
                ) : (
                    <div>
                        <h2>Successful verification</h2>
                        <Link to="/login">
                            <Button className="orange login-link">
                                Log in
                                <span className="login-icon-container">
                                    <Icon path={mdiLogin} size={1} />
                                </span>
                            </Button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

export default VerificationPage
