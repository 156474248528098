import SupplierProducts from "../../components/suppliers/supplier-products.component"
import SupplierUpdate from "../../components/suppliers/supplier-update.component"
import SupplierDetailsHome from "../../components/suppliers/suppliers-details-home.component"
import { ERoles } from "../../constants/enum"
import { ISubPageRoute } from "../../interfaces/pages"

const supplierDetailsRoutes: ISubPageRoute[] = [
    {
        path: "/admin/suppliers/:id",
        name: "Details",
        component: SupplierDetailsHome,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/suppliers/:id/update",
        name: "Edit",
        component: SupplierUpdate,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/suppliers/:id/products",
        name: "Add product",
        component: SupplierProducts,
        permission: [ERoles.SUPERADMIN],
    },
]

export default supplierDetailsRoutes
