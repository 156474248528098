//  Modules from the React eco-system
import React from "react"
import { useHistory } from "react-router-dom"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Own components
import CubeForm from "../forms/cube.form"

// Axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

const FormInitialValues = {
    name: "",
    region: "",
    description: "",
    shortDescription: "",
    fixServices: [],
    location: [47.18037912854737, 19.50450897216797],
    price: 0,
    terms_of_use: "",
    cancellation_policy: "",
    mqtt_name: "",
    home_assistant_ip: "",
    water_tank_size: 0,
    allowed_water_consumption: 0,
}

function CreateCube() {
    const history = useHistory()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: FormInitialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
            region: Yup.string().required("Required"),
            price: Yup.number().typeError("Must be a number").required("Required"),
            description: Yup.string().required("Required"),
            shortDescription: Yup.string().required("Required"),
            location: Yup.array().min(2).max(2).required("Required"),
            terms_of_use: Yup.string(),
            cancellation_policy: Yup.string(),
            mqtt_name: Yup.string().required("Required"),
            home_assistant_ip: Yup.string().required("Required"),
            water_tank_size: Yup.number().typeError("Must be a number").required("Required"),
            allowed_water_consumption: Yup.number().typeError("Must be a number").required("Required"),
        }),
        onSubmit: async (values, actions) => {
            try {
                const cubeData = {
                    name: values.name,
                    region: values.region,
                    price: values.price,
                    description: values.description,
                    short_description: values.shortDescription,
                    fix_services: values.fixServices,
                    location: {
                        type: "Point",
                        coordinates: values.location,
                    },
                    terms_of_use: values.terms_of_use,
                    cancellation_policy: values.cancellation_policy,
                    mqtt_name: values.mqtt_name,
                    home_assistant_ip: values.home_assistant_ip,
                    water_tank_size: values.water_tank_size,
                    allowed_water_consumption: values.allowed_water_consumption,
                }
                const response = await axiosClient.post("/cube", cubeData)
                toast.success("The cube is created")
                history.push(`/admin/cubes/${response.data._id}`)

                actions.resetForm({ values: FormInitialValues })
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <div>
            <CubeForm formik={formik} />
        </div>
    )
}

export default CreateCube
