import moment from "moment"
import React, { useMemo, useState } from "react"
import TableDisplayer from "../table.component"
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import PaymentIntentModal from "./paymentIntent.modal"
import { IPaymentIntent } from "../../interfaces/stripe"

interface IProps {
    paymentIntents: IPaymentIntent[]
}

function PaymentIntentsTable(props: IProps) {
    const [showModal, setShowModal] = useState(false)

    const [selectedPaymentIntent, setSelectedPaymentIntent] = useState<IPaymentIntent | undefined>()

    const columns = useMemo(
        () => [
            {
                Header: "Amount",
                accessor: "amount",
                Cell: (data: any) => `${(data.value / 100).toLocaleString()} ${data.row.original.currency.toUpperCase()}`,
                disableFilters: true,
            },
            {
                Header: "Status",
                accessor: "status",
                disableFilters: true,
            },
            {
                Header: "Created At",
                accessor: "created",
                Cell: (data: any) => moment.unix(data.value).format("YYYY. MMM DD., HH:mm"),
                disableFilters: true,
            },
            {
                Header: "Details",
                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <span
                                    onClick={() => {
                                        setSelectedPaymentIntent(data.row.original)
                                        setShowModal(true)
                                    }}
                                    className="icon-container btn orange">
                                    <Icon path={mdiDotsHorizontal} size={1} />
                                </span>
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
        ],
        []
    )

    return (
        <div>
            <div>{props.paymentIntents && <TableDisplayer columns={columns} data={props.paymentIntents} />}</div>
            {selectedPaymentIntent ? (
                <PaymentIntentModal
                    paymentIntent={selectedPaymentIntent}
                    show={showModal}
                    handleClose={() => {
                        setShowModal(false)
                        setSelectedPaymentIntent(undefined)
                    }}
                />
            ) : (
                <></>
            )}
        </div>
    )
}

export default PaymentIntentsTable
