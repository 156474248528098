import React, { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import axiosClient from "../api/api"
import { IOrder, ICube } from "../interfaces/cubes"
// redux, store
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"
import { UserContext } from "../store/UserProvider"

import ProductOrdersTable from "../components/reservations/product-order-table.component"
import Loader from "../components/loader.component"
import moment from "moment"

import Icon from "@mdi/react"
import { mdiCalendarBlankOutline, mdiCubeOutline, mdiPound } from "@mdi/js"
import "react-datepicker/dist/react-datepicker.css"

import DatePicker from "react-datepicker"

function ProductOrdersPage() {
    //Logged in user
    const { user } = useContext(UserContext)

    const history = useHistory()
    const dispatch = useDispatch()

    const [ordersToSupplier, setOrdersToSupplier] = useState<IOrder[]>([])

    const [isLoading, setIsLoading] = useState(false)

    const [queryFilters, setqueryFilters] = useState({
        startDate: moment().subtract(2, "months").toDate(),
    })

    useEffect(() => {
        // set page name in redux for the navbar
        dispatch(setPageName({ pageName: "Orders", pageUrl: "/admin/products", subPageName: "" }))
        fetchProductOrderItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryFilters])

    async function fetchProductOrderItems() {
        setIsLoading(true)
        try {
            const response = await axiosClient.get(`supplier/${user._id}/orders?startDate=${queryFilters.startDate}`)
            setOrdersToSupplier(response.data)
        } catch (error) {
            console.log("Failed to fetch products")
            history.push("/admin/404")
        }
        setIsLoading(false)
    }

    return (
        <div className="product-order-screen">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="filter-section">
                        <p>Created after: </p>
                        <DatePicker name="startDate" selected={queryFilters.startDate} onChange={(date) => date && setqueryFilters({ ...queryFilters, startDate: date })} />
                    </div>

                    {ordersToSupplier.length ? (
                        ordersToSupplier.map((order) => {
                            return (
                                <div className="table-tile order" key={order._id}>
                                    <div className="reservation-data">
                                        <div className="data-line">
                                            <p className="created-at">{`Created at ${moment(order.createdAt).format("DD. MMMM. YYYY.")}`}</p>
                                        </div>
                                        <div className="data-line">
                                            <Icon className="date-icon" path={mdiCalendarBlankOutline} size={1} />
                                            <p>{`${moment(order.reservation.startDate).format("DD. MMMM. YYYY.")}`}</p>
                                        </div>

                                        <div className="data-line">
                                            <Icon path={mdiCubeOutline} size={1} />
                                            <p>{`${(order.reservation.cube as ICube).name}`}</p>
                                        </div>

                                        <div className="data-line">
                                            <Icon path={mdiPound} size={1} />
                                            <p>{`${order.reservation.increment_id}`}</p>
                                        </div>
                                    </div>

                                    <ProductOrdersTable orderItems={order.order_items} setProductOrderItems={setOrdersToSupplier} />
                                </div>
                            )
                        })
                    ) : (
                        <div className="empty-data">
                            <p>There is no order for the selected period.</p>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ProductOrdersPage
