//  Modules from the React eco-system
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import { toast } from "react-toastify"

// Own components
import PhotoUploader from "../profil-image-uploader.component"

// Axios
import { AxiosError } from "axios"
import axiosClient from "../../api/api"

// Interfaces, enums
import { ISupplier } from "../../interfaces/persons"
import { IRegion } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Col, Row, Form, Button } from "react-bootstrap"

// Images
import default_avatar from "../../assets/img/default-avatar.jpg"

interface IProps {
    supplier: ISupplier
    setSupplier: Function
}

function SupplierUpdate(props: IProps) {
    const history = useHistory()

    const [showImageUploader, setShowImageUploader] = useState(false)

    const [regions, setRegions] = useState<IRegion[]>([])

    useEffect(() => {
        fetchRegions()
    }, [])

    async function fetchRegions() {
        try {
            const response = await axiosClient.get("/region?selectFields[]=name")
            setRegions(response.data)
        } catch (error) {
            toast.error("Failed to fetch regions")
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: props.supplier.firstname,
            lastname: props.supplier.lastname,
            email: props.supplier.email,
            phone: props.supplier.phone,
            // create options from the regions of the supplier
            regions: regions
                .filter((reg) => {
                    return props.supplier.regions.find((el) => el._id === reg._id)
                })
                .map((filteredReg) => {
                    return { value: filteredReg._id, label: filteredReg.name }
                }),
            company: props.supplier.company,
            taxNumber: props.supplier.tax_number,
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string(),
            lastname: Yup.string(),
            email: Yup.string().email("Az email cím nem megfeleő"),
        }),
        onSubmit: async (values, actions) => {
            try {
                if (props.supplier) {
                    const updateData = {
                        ...(values.firstname && values.firstname !== props.supplier.firstname && { firstname: values.firstname }),
                        ...(values.lastname && values.lastname !== props.supplier.lastname && { firstname: values.lastname }),
                        ...(values.email && values.email !== props.supplier.email && { email: values.email }),
                        ...(values.phone && values.phone !== props.supplier.phone && { phone: values.phone }),
                        ...(values.regions.map((reg) => reg.value).join() !== props.supplier.regions.map((reg) => reg._id).join() && { regions: values.regions.map((reg) => reg.value) }),
                        ...(values.company && values.company !== props.supplier.company && { company: values.company }),
                        ...(values.taxNumber && values.taxNumber !== props.supplier.tax_number && { tax_number: values.taxNumber }),
                    }

                    if (Object.keys(updateData).length) {
                        const response = await axiosClient.patch(`/supplier/${props.supplier._id}`, updateData)
                        props.setSupplier(response.data)
                        toast.success("Supplier is updated")
                        history.push(`/admin/suppliers/${props.supplier._id}`)
                    }
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    // TODO: proper type
    function getOptions(): any[] {
        return regions.map((reg) => {
            return { value: reg._id, label: reg.name }
        })
    }

    return (
        <div className="supplier-update">
            <div className="tile edit-container">
                <Row>
                    <Col xs={12} md={2} className="avatar-container">
                        <img className="user-picture" alt="profile-avatar" src={props.supplier.image ? props.supplier.image : default_avatar} />

                        <Button onClick={() => setShowImageUploader(true)} className="orange">
                            Upload image
                        </Button>
                    </Col>

                    <Col>
                        <Form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col xs={12} md={3}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Firstname:</Form.Label>
                                        <Form.Control
                                            onChange={formik.handleChange}
                                            name="firstname"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.firstname}
                                            type="text"
                                            placeholder="Firstname..."
                                        />
                                        {formik.touched.firstname && formik.errors.firstname ? <p className="input-error">{formik.errors.firstname}</p> : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Lastname:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="lastname" onBlur={formik.handleBlur} value={formik.values.lastname} type="text" placeholder="Lastname..." />
                                        {formik.touched.lastname && formik.errors.lastname ? <p className="input-error">{formik.errors.lastname}</p> : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="email" onBlur={formik.handleBlur} value={formik.values.email} type="email" placeholder="Email..." />
                                        {formik.touched.email && formik.errors.email ? <p className="input-error">{formik.errors.email}</p> : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Phone:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="phone" onBlur={formik.handleBlur} value={formik.values.phone} type="text" placeholder="Telefonszám..." />
                                        {formik.touched.phone && formik.errors.phone ? <p className="input-error">{formik.errors.phone}</p> : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={4}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Region:</Form.Label>
                                        {regions && <Select isMulti options={getOptions()} onChange={(t) => formik.setFieldValue("regions", [...t])} value={formik.values.regions} name="regions" />}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Company:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="company" onBlur={formik.handleBlur} value={formik.values.company} type="text" placeholder="Company..." />
                                        {formik.touched.company && formik.errors.company ? <p className="input-error">{formik.errors.company}</p> : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Tax number:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="taxNumber" onBlur={formik.handleBlur} value={formik.values.taxNumber} type="text" placeholder="Adószám..." />
                                        {formik.touched.taxNumber && formik.errors.taxNumber ? <p className="input-error">{formik.errors.taxNumber}</p> : null}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button className="orange" type="submit">
                                Update
                            </Button>
                        </Form>
                    </Col>
                </Row>

                <PhotoUploader
                    show={showImageUploader}
                    handleClose={() => setShowImageUploader(false)}
                    setPerson={(imageUrl: string) => props.setSupplier({ ...props.supplier, image: imageUrl })}
                    personId={props.supplier._id}
                    role={props.supplier.role}
                />
            </div>
        </div>
    )
}

export default SupplierUpdate
