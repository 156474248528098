//  Modules from the React eco-system
import { Link } from "react-router-dom"

// Styles, bootstrap, icons
import { Col, Row, Card } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiCalendarBlankOutline, mdiCubeOutline, mdiMapLegend, mdiAccountMultipleOutline } from "@mdi/js"

// Interfaces, enums
import { IData } from "../../pages/dashboard.page"

interface IProps {
    data: IData
}

function InfoCards(props: IProps) {
    return (
        <div className="card-section section">
            <Row>
                <Col xs={12} md={3}>
                    <div className="tile">
                        <Card.Header>
                            <div className="icon orange">
                                <Icon path={mdiAccountMultipleOutline} size={1.7} />
                            </div>
                            <div className="card-count">
                                <h4>{props.data.users ? props.data.users.length : 0}</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>
                                <Link to="/admin/users/main">Users</Link>
                            </Card.Title>
                        </Card.Body>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="tile">
                        <Card.Header>
                            <div className="icon">
                                <Icon path={mdiMapLegend} size={1.7} />
                            </div>
                            <div className="card-count">
                                <h4>{props.data.regions ? props.data.regions.length : 0}</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>
                                <Link to="/admin/regions/main">Regions</Link>
                            </Card.Title>
                        </Card.Body>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="tile">
                        <Card.Header>
                            <div className="icon">
                                <Icon path={mdiCubeOutline} size={1.7} />
                            </div>
                            <div className="card-count">
                                <h4>{props.data.cubes ? props.data.cubes.length : 0}</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>
                                <Link to="/admin/cubes/main">Cubes</Link>
                            </Card.Title>
                        </Card.Body>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="tile">
                        <Card.Header>
                            <div className="icon">
                                <Icon path={mdiCalendarBlankOutline} size={1.7} />
                            </div>
                            <div className="card-count">
                                <h4>{props.data.reservations ? props.data.reservations.length : 0}</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>
                                <Link to="/admin/reservations">Reservations</Link>
                            </Card.Title>
                        </Card.Body>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default InfoCards
