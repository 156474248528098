//  Modules from the React eco-system
import React, { useContext, useEffect } from "react"

// Third-party modules
import { toast } from "react-toastify"

// Own components
import AdminInfo from "../components/admin-info.component"
import ChangePassword from "../components/change-password.component"

// Redux, store
import { UserContext } from "../store/UserProvider"
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

// Interfaces, enums
import { IBaseAdmin } from "../interfaces/persons"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"
import ProfileUpdate from "../components/edit-profile-data.component"

function ProfilePage() {
    const dispatch = useDispatch()

    //Logged in user
    const admin: IBaseAdmin = useContext(UserContext).user

    useEffect(() => {
        dispatch(setPageName({ pageName: "Profile", pageUrl: "/admin/profile", subPageName: `${admin.firstname} ${admin.lastname}` }))
        displayWarning(admin)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [admin])

    function displayWarning(admin: IBaseAdmin) {
        !admin.image && toast.warning("You have not image")
        !admin.phone && toast.warning("You have not phone number")
    }

    return (
        <div>
            <Row>
                <Col xs={12} sm={6}>
                    <AdminInfo admin={admin} />
                </Col>
                <Col>
                    <ProfileUpdate />
                </Col>
                <Col xs={12} sm={6}>
                    <ChangePassword />
                </Col>
            </Row>
        </div>
    )
}

export default ProfilePage
