import CreateCube from "../../components/cubes/cubes-create-form.component"
import CubesTable from "../../components/cubes/cubes-table.component"
import { ISubPageRoute } from "../../interfaces/pages"
import { ERoles } from "../../constants/enum"

const cubesMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/cubes/main",
        name: "Infos",
        component: CubesTable,
        props: {
            hiddenColumns: ["id", "createdAt", "services", "price", "region", "short_description"],
        },
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/cubes/main/update",
        name: "Create Cube",
        component: CreateCube,
        permission: [ERoles.SUPERADMIN],
    },
]

export default cubesMainRoutes
