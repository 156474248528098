//  Modules from the React eco-system
import React from "react"

// thirs party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// interfaces, enums
import { IReservation } from "../../interfaces/cubes"

// style, bootstrap, icons
import { Modal, Button, Form } from "react-bootstrap"

interface IProps {
    show: boolean
    handleClose: any
    orderid: string
    setReservation: Function
}

const FormInitialValues = {
    name: "",
    description: "",
    price: 0,
}

function AddServiceModal(props: IProps) {
    const formik = useFormik({
        initialValues: FormInitialValues,
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            price: Yup.number().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const bodyData = {
                    orderid: props.orderid,
                    serviceOrders: [
                        {
                            name: values.name,
                            description: values.description,
                            price: values.price,
                        },
                    ],
                }
                const response = await axiosClient.post("/reservation/orderitem", bodyData)
                props.setReservation((prev: IReservation) => {
                    return {
                        ...prev,
                        orders: prev.orders.map((order) => {
                            if (response.data._id === order._id) {
                                return response.data
                            } else {
                                return order
                            }
                        }),
                    }
                })
                props.handleClose()
                toast.success("Service is created")
                resetForm({
                    values: FormInitialValues,
                })
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control onChange={formik.handleChange} name="name" onBlur={formik.handleBlur} value={formik.values.name} type="text" placeholder="Service name..." />
                            {formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Price:</Form.Label>
                            <Form.Control onChange={formik.handleChange} name="price" onBlur={formik.handleBlur} value={formik.values.price} type="number" placeholder="Price..." />
                            {formik.touched.price && formik.errors.price ? <div>{formik.errors.price}</div> : null}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasic">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                onChange={formik.handleChange}
                                name="description"
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                type="text"
                                placeholder="Description..."
                            />
                            {formik.touched.description && formik.errors.description ? <div>{formik.errors.description}</div> : null}
                        </Form.Group>
                        <Button className="orange" type="submit">
                            Create
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddServiceModal
