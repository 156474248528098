//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import DatePicker from "react-datepicker"
import { toast } from "react-toastify"

// Axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// Interfaces, enums
import { IUser } from "../../interfaces/persons"
import { ICube, IReservation } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Form, Button, Row, Col } from "react-bootstrap"
import "react-datepicker/dist/react-datepicker.css"

interface IProps {
    cube: ICube
    setCube: Function
}

const FormInitialValues = {
    user: { label: "", value: "" },
    startDate: undefined,
    endDate: undefined,
}

/**
 * create reservation form element
 * TODO: disable reserved days
 * @param props cube
 * @returns
 */

function CreateReservation(props: IProps) {
    // users for the selection
    const [users, setUsers] = useState<IUser[]>([])

    useEffect(() => {
        fetchUsers()
    }, [])

    // fetch all user from the database
    async function fetchUsers() {
        try {
            const response = await axiosClient.get("/user")
            setUsers(response.data)
        } catch (error) {
            toast.error("Failed to fetch users")
        }
    }

    const formik = useFormik({
        initialValues: FormInitialValues,
        validationSchema: Yup.object({
            user: Yup.object().required("Required"),
            startDate: Yup.date().min(new Date(), "Date is invalid").required("Start date is required"),
            endDate: Yup.date().required("End date is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (values.user && values.startDate && values.endDate) {
                    const reservationData = {
                        user: values.user.value,
                        cube: props.cube._id,
                        startDate: values.startDate,
                        endDate: values.endDate,
                    }
                    const response = await axiosClient.post("/reservation", reservationData)
                    props.setCube((prev: ICube) => {
                        return { ...prev, reservations: [...(prev.reservations as IReservation[]), response.data] }
                    })
                    toast.success("The reservation is created")
                    resetForm({
                        values: FormInitialValues,
                    })
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })
    return (
        <div>
            {users.length ? (
                <Row>
                    <Col xs={12} md={3} className="tile">
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group className="mb-3" controlId="formReservation">
                                <Form.Label>User</Form.Label>
                                {users && (
                                    <Select
                                        options={users.map((user) => {
                                            return { value: user._id, label: (user as IUser).firstname }
                                        })}
                                        onChange={(t) => formik.setFieldValue("user", t)}
                                        value={formik.values.user}
                                        name="user"
                                    />
                                )}
                                {formik.touched.user && formik.errors.user ? <p className="input-error">{formik.errors.user}</p> : null}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formReservation">
                                <Form.Label>Start date</Form.Label>
                                <DatePicker name="startDate" selected={formik.values.startDate} onChange={(date) => formik.setFieldValue("startDate", date)} />
                                {formik.touched.startDate && formik.errors.startDate ? <p className="input-error">{formik.errors.startDate}</p> : null}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formReservation">
                                <Form.Label>End date</Form.Label>
                                <DatePicker name="endDate" selected={formik.values.endDate} onChange={(date) => formik.setFieldValue("endDate", date)} />
                                {formik.touched.endDate && formik.errors.endDate ? <p className="input-error">{formik.errors.endDate}</p> : null}
                            </Form.Group>

                            <Button className="orange" type="submit">
                                Create
                            </Button>
                        </Form>
                    </Col>
                </Row>
            ) : (
                <div>There are no users in the database</div>
            )}
        </div>
    )
}

export default CreateReservation
