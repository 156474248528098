import CreateSupplier from "../../components/suppliers/suppliers-create-form.component"
import SuppliersTable from "../../components/suppliers/suppliers-table.component"
import { ERoles } from "../../constants/enum"
import { ISubPageRoute } from "../../interfaces/pages"

const suppliersMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/suppliers/main",
        name: "Infos",
        component: SuppliersTable,
        props: {
            hiddenColumns: ["email", "phone", "taxNumber", "company", "regions", "products", "registeredAt", "emailVerified"],
        },
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/suppliers/main/update",
        name: "Create Supplier",
        component: CreateSupplier,
        permission: [ERoles.SUPERADMIN],
    },
]

export default suppliersMainRoutes
