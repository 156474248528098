import React, { useEffect, useState } from "react"
import { ICubeFeature, ICube } from "../../interfaces/cubes"

import Icon from "@mdi/react"
import { mdiCloseBoxOutline } from "@mdi/js"
import axiosClient from "../../api/api"
import Select from "react-select"
import { Form } from "react-bootstrap"
import { toast } from "react-toastify"
import axios, { AxiosError } from "axios"

interface IProps {
    cubeId: string
    cubeFeatures: ICubeFeature[]
    highlightedCubeFeatures: string[]
    setCube: Function
}

function CubeFeatures(props: IProps) {
    const [allCubeFeatures, setAllCubeFeatures] = useState<ICubeFeature[]>([])

    useEffect(() => {
        fetchCubeFeatures()
    }, [])

    async function fetchCubeFeatures() {
        try {
            const response = await axiosClient.get("/cubefeature")
            setAllCubeFeatures(response.data)
        } catch (error) {
            toast.error("Failed to fetch cube features")
        }
    }

    /**
     * add/remove features from highlighted_features
     * @param featureId
     */
    async function handleFeatureHighlight(featureId: string) {
        try {
            const newHiglighted = props.highlightedCubeFeatures.includes(featureId)
                ? props.highlightedCubeFeatures.filter((feature) => feature !== featureId)
                : [...props.highlightedCubeFeatures, featureId]
            await axiosClient.patch(`/cube/${props.cubeId}`, { highlighted_features: newHiglighted })

            props.setCube((prev: ICube) => {
                return { ...prev, highlighted_features: newHiglighted }
            })
            toast.success("Feature is updated")
        } catch (error) {
            toast.error("Failed to update item")
        }
    }

    /**
     * handle remove feature from the cube
     * @param featureId
     */
    async function handleFetaureRemove(featureId: string) {
        try {
            await axiosClient.delete(`/cube/cubefeature/${props.cubeId}`, {
                data: {
                    cubeFeature: featureId,
                },
            })
            props.setCube((prev: ICube) => {
                return { ...prev, cube_features: prev.cube_features.filter((feature) => feature._id !== featureId) }
            })
            toast.success("Feature is removed")
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Failed to remove cube feature")
            }
        }
    }

    /**
     * add feature to the cube if it is selected in the dropdown
     * @param featureId
     */
    async function handleSelect(featureId: string) {
        try {
            await axiosClient.patch(`/cube/${props.cubeId}`, { cube_features: [...props.cubeFeatures, featureId] })
            const feature = allCubeFeatures.find((feature) => feature._id === featureId)
            if (feature) {
                props.setCube((prev: ICube) => {
                    return { ...prev, cube_features: [...prev.cube_features, feature] }
                })
            }
            toast.success("Item is added to the list")
        } catch (error) {
            toast.error("Failed to add item to the list")
        }
    }

    return (
        <div>
            <h4 className="feature-title">Cube features</h4>
            <div className="select-container">
                <Select
                    options={allCubeFeatures
                        .filter((feature) => !props.cubeFeatures.find((cubefeature) => cubefeature._id === feature._id))
                        .map((feature) => {
                            return { value: feature._id, label: feature.name }
                        })}
                    onChange={(t) => t && handleSelect(t.value)}
                    value={{ value: "", label: "" }}
                    name="user"
                />
            </div>

            {props.cubeFeatures.map((feature) => {
                return (
                    <div key={feature._id} className="feature-line">
                        <img className="feature-image" src={feature.image} alt="feature" />
                        <p className="feature-name">{feature.name}</p>
                        <div className="highlighted-container">
                            <p className={`highlighted-text ${props.highlightedCubeFeatures.includes(feature._id) ? "" : "higlighted-false"}`}>Highlighted</p>
                            <Form.Group className="check-feature" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" onChange={() => handleFeatureHighlight(feature._id)} name="enabled" checked={props.highlightedCubeFeatures.includes(feature._id)} />
                            </Form.Group>
                        </div>
                        <div className="feature-remove-container">
                            <div onClick={() => handleFetaureRemove(feature._id)}>
                                <Icon className="feature-remove" path={mdiCloseBoxOutline} size={1} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default CubeFeatures
