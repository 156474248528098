import { useMemo } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import { IReservation } from "../../interfaces/cubes"
import { IUser } from "../../interfaces/persons"
import TableDisplayer from "../table.component"
import { textFilter, dateFilter, dateRangeFilter, selectColumnFilter } from "../column-filter.component"
import Icon from "@mdi/react"
import { mdiTune } from "@mdi/js"

interface IProps {
    reservations: IReservation[]
    hiddenColumns?: string[]
}

// TODO: proper types (Cell data)
function ReservationsTable(props: IProps) {
    const columns = useMemo(
        () => [
            {
                Header: "Details",
                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/admin/reservations/${data.row.original._id}`}>
                                    <span className="icon-container btn orange">
                                        <Icon path={mdiTune} size={1} />
                                    </span>{" "}
                                </Link>

                                {data.value}
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: "User",
                accessor: (data: IReservation) => {
                    return `${(data.user as IUser).firstname} ${(data.user as IUser).lastname}`
                },
                Cell: (data: any) => {
                    return <Link to={`/admin/users/${data.row.original.user._id}`}>{data.value}</Link>
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("user"),
            },
            {
                Header: "Cube",
                accessor: "cube.name",
                Cell: (data: any) => {
                    return <Link to={`/admin/cubes/${data.row.original.cube._id}`}>{data.value}</Link>
                },
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("cube"),
            },
            {
                Header: "Start date",
                accessor: "startDate",
                Cell: (data: any) => moment(data.value).format("YYYY.MM.DD."),
                Filter: dateFilter,
                filter: dateRangeFilter,
                isHidden: props.hiddenColumns?.includes("startDate"),
            },
            {
                Header: "End date",
                accessor: "endDate",
                Cell: (data: any) => moment(data.value).format("YYYY.MM.DD."),
                Filter: dateFilter,
                filter: dateRangeFilter,
                isHidden: props.hiddenColumns?.includes("endDate"),
            },
            {
                Header: "Id",
                accessor: "increment_id",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("id"),
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("status"),
            },
            {
                Header: "Created at",
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("createdAt"),
            },
        ],
        [props.hiddenColumns]
    )

    return <div>{props.reservations && <TableDisplayer columns={columns} data={props.reservations} />}</div>
}

export default ReservationsTable
