//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// interfaces, enums
import { IProduct, IReservation } from "../../interfaces/cubes"

// style, bootstrap, icons
import { Modal, Button, Form } from "react-bootstrap"

interface IProps {
    show: boolean
    handleClose: any
    orderid: string
    regionid: string
    setReservation: Function
}

const FormInitialValues = {
    product: "",
    quantity: 1,
    description: "",
    price: 0,
}

function AddProductModal(props: IProps) {
    // products from the fetch
    const [products, setProducts] = useState<IProduct[]>([])

    useEffect(() => {
        // fetch products
        fetchProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.regionid])

    // fetch products
    async function fetchProducts() {
        try {
            const response = await axiosClient.get(`/product?region=${props.regionid}`)
            setProducts(response.data)
        } catch (error) {
            toast.error("Failed to get products from the database")
        }
    }

    const formik = useFormik({
        initialValues: FormInitialValues,
        validationSchema: Yup.object({
            product: Yup.string().required("Required"),
            quantity: Yup.number().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const bodyData = {
                    orderid: props.orderid,
                    productOrders: [
                        {
                            productId: values.product,
                            quantity: values.quantity,
                        },
                    ],
                }
                const response = await axiosClient.post("/reservation/orderitem", bodyData)
                props.setReservation((prev: IReservation) => {
                    return {
                        ...prev,
                        orders: prev.orders.map((order) => {
                            if (response.data._id === order._id) {
                                return response.data
                            } else {
                                return order
                            }
                        }),
                    }
                })
                props.handleClose()
                toast.success("The product is added to the reservation")
                resetForm({
                    values: FormInitialValues,
                })
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formProduct">
                            <Form.Label>Region</Form.Label>
                            <Form.Select onChange={formik.handleChange} name="product" value={formik.values.product} aria-label="">
                                <option key="blankChoice" value="">
                                    Product...
                                </option>
                                {products.map((product) => {
                                    return (
                                        <option key={product._id} value={product._id}>
                                            {product.name}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                            {formik.touched.product && formik.errors.product ? <p className="input-error">{formik.errors.product}</p> : null}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formProduct">
                            <Form.Label>Quantity:</Form.Label>
                            <Form.Control onChange={formik.handleChange} name="quantity" onBlur={formik.handleBlur} value={formik.values.quantity} type="number" placeholder="Quantity..." />
                            {formik.touched.quantity && formik.errors.quantity ? <div>{formik.errors.quantity}</div> : null}
                        </Form.Group>

                        <Button className="orange" type="submit">
                            Create
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddProductModal
