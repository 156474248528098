//  Modules from the React eco-system
import React from "react"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// axios
import axiosClient from "../../api/api"
import axios, { AxiosError } from "axios"

// Interfaces, enums
import { IMainCategory } from "../../interfaces/cubes"

// style, bootstrap, icons
import { Modal, Button, Form, Accordion, Popover, OverlayTrigger } from "react-bootstrap"

interface IProps {
    show: boolean
    handleClose: any
    category: IMainCategory
    setCategories?: Function
}

function UpdateCategory(props: IProps) {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.category.name,
            second_name: props.category.second_name || "",
            description: props.category.description,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            second_name: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
        }),
        onSubmit: async (values) => {
            try {
                const updateData = {
                    ...(formik.values.name && formik.values.name !== props.category.name && { name: values.name }),
                    ...(formik.values.second_name && formik.values.second_name !== props.category.second_name && { second_name: values.second_name }),
                    ...(formik.values.description && formik.values.description !== props.category.description && { description: values.description }),
                }
                const response = await axiosClient.patch(`/category/${props.category._id}`, updateData)
                props.setCategories &&
                    props.setCategories((prev: IMainCategory[]) => {
                        return prev.map((category) => {
                            return category._id === response.data._id ? response.data : category
                        })
                    })
                toast.success("The category is updated")
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    const imageFormik = useFormik({
        initialValues: { image: undefined },
        validationSchema: Yup.object({
            image: Yup.mixed().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (values.image) {
                    const formData = new FormData()
                    formData.append("type", "category")
                    formData.append("image", values.image)

                    const response = await axiosClient.patch(`/category/${props.category._id}/image`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    props.setCategories &&
                        props.setCategories((prev: IMainCategory[]) => {
                            return prev.map((category) => {
                                return category._id === response.data._id ? response.data : category
                            })
                        })
                    props.handleClose()

                    toast.success("The category is updated")
                    resetForm()
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    /**
     * delete category
     */
    async function deleteCategory() {
        try {
            await axiosClient.delete(`/category/${props.category._id}`)
            props.handleClose()
            props.setCategories &&
                props.setCategories((prev: IMainCategory[]) => {
                    return prev.filter((category) => category._id !== props.category._id)
                })
            toast.success("The category is removed")
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Failed to delete the category")
            }
        }
    }

    /**
     * popover for the category deletion
     */
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Delete category</Popover.Header>
            <Popover.Body>
                <div>
                    <p>Delete this category?</p>
                    <Button className="btn orange" onClick={deleteCategory}>
                        Delete
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    )

    return (
        <>
            <Modal
                className="create-category"
                show={props.show}
                onHide={() => {
                    props.handleClose()
                    formik.resetForm()
                    imageFormik.resetForm()
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.category.image && <img className="preview-image" src={props.category.image} alt="category" />}

                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formCategory">
                            <Form.Label>Name</Form.Label>
                            <Form.Control onChange={formik.handleChange} name="name" onBlur={formik.handleBlur} value={formik.values.name} type="text" placeholder="Name of the category..." />
                            {formik.touched.name && formik.errors.name ? <p className="input-error">{formik.errors.name}</p> : null}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formCategory">
                            <Form.Label>Second Name</Form.Label>
                            <Form.Control
                                onChange={formik.handleChange}
                                name="second_name"
                                onBlur={formik.handleBlur}
                                value={formik.values.second_name}
                                type="text"
                                placeholder="Second name of the category..."
                            />
                            {formik.touched.second_name && formik.errors.second_name ? <p className="input-error">{formik.errors.second_name}</p> : null}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formCategory">
                            <Form.Label>Long Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                onChange={formik.handleChange}
                                name="description"
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                type="text"
                                placeholder="Description of the category..."
                            />
                            {formik.touched.description && formik.errors.description ? <p className="input-error">{formik.errors.description}</p> : null}
                        </Form.Group>

                        <Button className="orange submit-button" type="submit">
                            Update category
                        </Button>
                    </Form>
                    {imageFormik.values.image && <img className="preview-image" src={URL.createObjectURL(imageFormik.values.image)} alt="uploaded" />}

                    <Form onSubmit={imageFormik.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formCategoryImage">
                            <Form.Label>Image:</Form.Label>
                            <Form.Control
                                onChange={(evt) => {
                                    imageFormik.setFieldValue("image", (evt.target as any).files[0])
                                }}
                                name="image"
                                type="file"
                            />
                            {imageFormik.touched.image && imageFormik.errors.image ? <div className="input-error">{imageFormik.errors.image}</div> : null}
                        </Form.Group>

                        <Button className="orange submit-button" type="submit">
                            Update image
                        </Button>
                    </Form>
                    <Accordion className="delete-dropdown">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Delete</Accordion.Header>
                            <Accordion.Body>
                                <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose>
                                    <Button className="submit-button" variant="danger">
                                        Delete
                                    </Button>
                                </OverlayTrigger>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UpdateCategory
