//  Modules from the React eco-system
import { useState, useEffect, useContext } from "react"

// Third-party modules
import { toast } from "react-toastify"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"
import Loader from "../components/loader.component"

// Navigation
import { Route, Switch, useParams } from "react-router"
import productDetailsRoutes from "../routes/details/product-details.route"
import { useHistory } from "react-router-dom"

// Axios
import axiosClient from "../api/api"

// Redux, store
import { useDispatch } from "react-redux"
import { setPageName } from "../store/pageSlice"
import { UserContext } from "../store/UserProvider"

// Interfaces, enums
import { IProduct } from "../interfaces/cubes"
import { ISubPageRoute } from "../interfaces/pages"
import { ERoles } from "../constants/enum"

interface RouteParams {
    id: string
}

function ProductDetailsPage() {
    //Logged in user
    const { user } = useContext(UserContext)

    // dispatch for the redux
    const dispatch = useDispatch()
    const history = useHistory()

    const params = useParams<RouteParams>()

    // the product from the fetch
    const [product, setProduct] = useState<IProduct>()

    useEffect(() => {
        //fetch product
        fetchProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(setPageName({ pageName: "Products", pageUrl: "/admin/products", subPageName: product ? product.name : "" }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product])

    /**
     * fetch the product (by the id from the params)
     */
    async function fetchProduct() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/product/${params.id}`)
                const product: IProduct = response.data
                displayWarning(product)
                setProduct(product)
            } else {
                history.push("/admin/404")
            }
        } catch (error) {
            console.log("Failed to fetch product")
            history.push("/admin/404")
        }
    }

    function displayWarning(product: IProduct) {
        !product.main_category && toast.warning("This product has not category")
        !product.description && toast.warning("This product has not description")
        !product.image && toast.warning("This product has not image")
        !product.short_description && toast.warning("This product has not short description")
        !product.supplier && toast.warning("This product has not supplier")
        !product.enabled && toast.warning("This product is not enabled in the app")
    }

    return (
        <div>
            {product ? (
                <div>
                    <SubPageNavbar
                        routes={productDetailsRoutes.filter((route: ISubPageRoute) => {
                            return route.permission?.includes(user.role as ERoles)
                        })}
                        id={product._id}
                    />

                    <Switch>
                        {productDetailsRoutes
                            .filter((route: ISubPageRoute) => {
                                return route.permission?.includes(user.role as ERoles)
                            })
                            .map((route: ISubPageRoute, key: number) => {
                                return (
                                    <Route
                                        key={key}
                                        path={route.path}
                                        exact
                                        render={() => {
                                            return <route.component product={product} setProduct={setProduct} />
                                        }}
                                    />
                                )
                            })}
                    </Switch>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default ProductDetailsPage
