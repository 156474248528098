//  Modules from the React eco-system
import React, { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"

// Axios
import axiosClient from "../api/api"

// Redux, store
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"
import { UserContext } from "../store/UserProvider"

// Own components
import ActivityLogs from "../components/dashboard/activity-logs.component"
import InfoCards from "../components/dashboard/infocards.component"
import DashboardCharts from "../components/dashboard/dashboard-charts.component"
import Loader from "../components/loader.component"

// Interfaces, enums
import { ICube, IRegion, IReservation, IActivityLog } from "../interfaces/cubes"
import { IUser } from "../interfaces/persons"

// Styles, bootstrap, icons
import { Col, Row } from "react-bootstrap"
import { ERoles } from "../constants/enum"

export interface IData {
    cubes: ICube[]
    regions: IRegion[]
    users: IUser[]
    reservations: IReservation[]
}

function Dashboard() {
    //Logged in user
    const { user } = useContext(UserContext)

    const dispatch = useDispatch()
    const history = useHistory()

    const [data, setData] = useState<IData>({ cubes: [], regions: [], users: [], reservations: [] })

    const [isLoading, setIsLoading] = useState(true)

    const [logs, setLogs] = useState<IActivityLog[]>([])

    useEffect(() => {
        dispatch(setPageName({ pageName: "Dashboard", pageUrl: "/admin/dashboard", subPageName: "" }))

        fetchData()
        fetchLogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchLogs() {
        try {
            const response = await axiosClient.get("/log")
            setLogs(response.data)
        } catch (error) {
            console.log("Failed to fetch logs")
        }
    }

    async function fetchData() {
        setIsLoading(true)
        try {
            const cubeResponse = await axiosClient.get("/cube")
            const regionResponse = await axiosClient.get("/region")
            const reservationResponse = await axiosClient.get("/reservation")
            const userResponse = await axiosClient.get("/user")
            setData({ ...data, cubes: cubeResponse.data, regions: regionResponse.data, reservations: reservationResponse.data, users: userResponse.data })
        } catch (error) {
            console.log("Failed to fetch cubes")
            history.push("/admin/404")
        }
        setIsLoading(false)
    }

    return (
        <div className="dashboard-page">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Row>
                        <Col>
                            <InfoCards data={data} />
                        </Col>
                    </Row>
                    {data.reservations.length ? <DashboardCharts data={data} /> : <></>}
                    {user.role === ERoles.SUPERADMIN ? (
                        <Row>
                            <Col>
                                <div className="table-tile">
                                    <ActivityLogs logs={logs} />
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    )
}

export default Dashboard
