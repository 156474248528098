import CreateReservation from "../../components/cubes/create-reservation.component"
import CubeCalendar from "../../components/cubes/cube-calendar.component"
import CubeHome from "../../components/cubes/cube-details-home.component"
import CubeReservations from "../../components/cubes/cube-reservations.component"
import CubeSensors from "../../components/cubes/cube-sensors.component"
import CubeShop from "../../components/cubes/cube-shop.component"
import UpdateCube from "../../components/cubes/cube-update.component"
import { ERoles } from "../../constants/enum"
import { ISubPageRoute } from "../../interfaces/pages"

const cubesRoutes: ISubPageRoute[] = [
    {
        path: "/admin/cubes/:id",
        name: "Details",
        component: CubeHome,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER],
    },
    {
        path: "/admin/cubes/:id/update",
        name: "Edit",
        component: UpdateCube,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/cubes/:id/reservations",
        name: "Reservations",
        component: CubeReservations,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },
    {
        path: "/admin/cubes/:id/createres",
        name: "Add reservation",
        component: CreateReservation,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/cubes/:id/calendar",
        name: "Calendar",
        component: CubeCalendar,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },

    {
        path: "/admin/cubes/:id/sensors",
        name: "Sensors",
        component: CubeSensors,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },
    {
        path: "/admin/cubes/:id/shop",
        name: "Shop",
        component: CubeShop,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR],
    },
]

export default cubesRoutes
