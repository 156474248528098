import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import axiosClient from "../../api/api"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import { AxiosError } from "axios"
import { Form, Button } from "react-bootstrap"
import { IStoredProduct, IShopProduct } from "../../interfaces/cubes"

interface IProps {
    show: boolean
    handleClose: any
    cubeRegion: string
    cubeId: string
    setShopProducts: Function
    shopProductIds: string[]
}

const FormInitialValues = {
    product: { label: "", value: "" },
    quantity: 0,
}

function AddShopProduct(props: IProps) {
    const [shopProductsInRegion, setShopProductsInRegion] = useState<IStoredProduct[]>([])

    useEffect(() => {
        fetchShopProdOfRegion()
    }, [props.cubeRegion])

    async function fetchShopProdOfRegion() {
        try {
            const response = await axiosClient.get(`/product?shopProduct=true&region=${props.cubeRegion}`)
            setShopProductsInRegion(response.data)
        } catch (error) {
            toast.error("Failed to fetch the shop products of the region")
        }
    }

    const formik = useFormik({
        initialValues: FormInitialValues,
        validationSchema: Yup.object({
            product: Yup.object().required("Required"),
            quantity: Yup.number().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (!values.product.value || !values.quantity) {
                    toast.error("Product and quantity are required")
                    return
                }
                const updateData = {
                    productId: values.product.value,
                    quantity: values.quantity,
                }
                const response = await axiosClient.patch(`/cube/${props.cubeId}/shop`, updateData)
                const shopProducts = response.data.shopProducts.map((shopProduct: IShopProduct) => {
                    return { ...shopProduct.product, quantity: shopProduct.quantity }
                })
                props.setShopProducts && props.setShopProducts(shopProducts || [])

                toast.success("The shop product is added to the cube")
                resetForm({
                    values: FormInitialValues,
                })
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <>
            <Modal
                className="create-category"
                show={props.show}
                onHide={() => {
                    props.handleClose()
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Product to Shop</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{`Available products ${shopProductsInRegion.length}`}</p>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formReservation">
                            <Form.Label>Product</Form.Label>
                            {shopProductsInRegion && (
                                <Select
                                    options={shopProductsInRegion.map((product) => {
                                        return { value: product._id, label: product.name, isDisabled: props.shopProductIds.includes(product._id) }
                                    })}
                                    onChange={(t) => formik.setFieldValue("product", t)}
                                    value={formik.values.product}
                                    name="user"
                                />
                            )}
                            {formik.touched.product && formik.errors.product ? <p className="input-error">{formik.errors.product}</p> : null}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formSupplier">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control onChange={formik.handleChange} name="quantity" onBlur={formik.handleBlur} value={formik.values.quantity} type="number" placeholder="Quantity..." />
                            {formik.touched.quantity && formik.errors.quantity ? <p className="input-error">{formik.errors.quantity}</p> : null}
                        </Form.Group>

                        <Button className="orange" type="submit">
                            Create
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddShopProduct
