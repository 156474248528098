//  Modules from the React eco-system
import { Link } from "react-router-dom"

// Third-party modules
import moment from "moment"
import { toast } from "react-toastify"

// Axios
import axiosClient from "../api/api"

// Store
import { useContext } from "react"
import { UserContext } from "../store/UserProvider"

// Interfaces, enums
import { IBaseAdmin } from "../interfaces/persons"
import { IRegion } from "../interfaces/cubes"
import { ERoles } from "../constants/enum"

// Images
import default_avatar from "../assets/img/default-avatar.jpg"

interface IProps {
    admin: IBaseAdmin
}

/**
 * Main infos of one administrator (Super admin, operator or supplier)
 * @param props
 * @returns
 */
function AdminInfo(props: IProps) {
    //Logged in user
    const loggedInAdmin: IBaseAdmin = useContext(UserContext).user

    async function inviteAgain() {
        try {
            await axiosClient.post(`auth/admin/invite`, { email: props.admin.email })
            toast.success("Invitation email sent")
        } catch (error) {
            toast.error("Invitation failed")
        }
    }
    return (
        <div className="tile operator-info">
            <div className="avatar">
                <img className="user-picture" alt="profile-avatar" src={props.admin.image ? props.admin.image : default_avatar} />
            </div>
            <p>{props.admin.role}</p>
            <h2>{props.admin ? `${props.admin.firstname} ${props.admin.lastname}` : "-"}</h2>

            <div className={`invitation-info ${props.admin.isVerified ? "verified-true" : "verified-false"}`}>
                <p>{`${props.admin.isVerified ? "Verified Account" : "Account is not verified"}`}</p>
                {loggedInAdmin.role === ERoles.SUPERADMIN && !props.admin.isVerified ? (
                    <p className="inivte-again" onClick={inviteAgain}>
                        Invite
                    </p>
                ) : (
                    <></>
                )}
            </div>

            <p>Email: {props.admin ? props.admin.email : "-"}</p>
            <p>Registration: {moment(props.admin.createdAt).format("YYYY. MM. DD.")}</p>

            {props.admin.phone && <p>Phone: {props.admin.phone}</p>}
            {props.admin.company && <p>Company: {props.admin.company}</p>}
            {props.admin.tax_number && <p>Tax number: {props.admin.tax_number}</p>}
            {props.admin.regions && (
                <p>
                    Regions:
                    {props.admin.regions.map((reg) => {
                        return (
                            <span>
                                {" "}
                                <Link to={`/admin/regions/${reg._id}`}>{reg.name}</Link>;{" "}
                            </span>
                        )
                    })}
                </p>
            )}

            {props.admin.region && (
                <p>
                    Region: <Link to={`/admin/regions/${(props.admin.region as IRegion)._id}`}>{(props.admin.region as IRegion).name}</Link>{" "}
                </p>
            )}
        </div>
    )
}

export default AdminInfo
