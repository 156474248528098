//  Modules from the React eco-system
import { useContext } from "react"

// Third-party modules
import moment from "moment"

// Redux, store
import { UserContext } from "../../store/UserProvider"

// axios
import axiosClient from "../../api/api"

// interfaces, enums
import { IProduct } from "../../interfaces/cubes"
import { ERoles } from "../../constants/enum"

// bootstrap, icons, styles
import Icon from "@mdi/react"
import { mdiFoodOutline } from "@mdi/js"
import { Form } from "react-bootstrap"

interface IProps {
    product: IProduct
    setProduct: Function
}

/**
 * display main infos on the product
 * @param props
 * @returns
 */
function ProductInfos(props: IProps) {
    //Logged in user
    const { user } = useContext(UserContext)

    /**
     * switch the enabled state of the product
     * @param evt
     */
    async function changeEnabled(evt: any) {
        try {
            const response = await axiosClient.patch(`/product/${props.product._id}`, { enabled: evt.target.checked })
            props.setProduct(response.data)
        } catch (error) {
            console.log("Failed to switch product enabled")
        }
    }

    return (
        <div className="product-infos tile">
            <div className="large-icon-container">
                <Icon path={mdiFoodOutline} size={2.7} />
            </div>
            <h2>{props.product.name}</h2>
            <div className="enabled-container">
                <div className="enabled-info">
                    <p className={`enabled ${props.product.enabled}`}>{props.product.enabled ? "Enabled" : "Not Enabled"}</p>
                </div>
                {user.role === ERoles.SUPERADMIN ? (
                    <Form>
                        <Form.Check type="switch" id="custom-switch" checked={props.product.enabled} onChange={changeEnabled} />
                    </Form>
                ) : (
                    <></>
                )}
            </div>
            <p>{props.product.highlightedInApp ? "Highlighted in the application" : ""}</p>
            <p>{props.product.shopProduct ? "Shop product" : ""}</p>
            <p>User price: {props.product.user_price}</p>
            <p>Purchase price: {props.product.purchase_price}</p>
            <p>Created: {moment(props.product.createdAt).format("YYYY.MM.DD.")}</p>
        </div>
    )
}

export default ProductInfos
