//  Modules from the React eco-system
import React, { useState } from "react"
import { useHistory } from "react-router"

// Third-party modules
import moment from "moment"

// Own components
import ConfirmModal from "../confirm-modal.component"
import EventCalendar from "../event-calendar.component"

// Axios
import axiosClient from "../../api/api"

// Interfaces, enums
import { IReservation, EventTypes, ICube } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    cube: ICube
    setCube: Function
}

function CubeCalendar(props: IProps) {
    const history = useHistory()

    const [showEmptyDayConfirm, setShowEmptyDayConfirm] = useState<boolean>(false)
    const [showDisabledDayConfirm, setShowDisabledDayConfirm] = useState(false)

    const [selectedDate, setSelectedDate] = useState<string>("")

    function createEvent() {
        if (props.cube.reservations && props.cube.disabled_days) {
            let events = (props.cube.reservations as IReservation[]).map((reservation) => {
                return { start: reservation.startDate, type: "reservation", allDay: true, end: reservation.endDate, title: `id: ${reservation.increment_id}`, id: reservation._id }
            })
            events = [
                ...events,
                ...props.cube.disabled_days.map((disableDay) => {
                    return { start: disableDay, type: "disabled", allDay: true, end: disableDay, title: `disabled day`, id: "" }
                }),
            ]
            return events
        }
    }

    function checkIsReserved() {
        if (props.cube.reservations) {
            return (props.cube.reservations as IReservation[]).some((res) => {
                return moment(selectedDate).isBetween(res.startDate, res.endDate, "day", "[]")
            })
        }
    }

    async function setDateToDisabled() {
        if (!props.cube.reservations) {
            return
        }
        try {
            const response = await axiosClient.put(`/cube/disabledday/${props.cube._id}`, {
                disabledDays: [selectedDate],
            })
            props.setCube(response.data)
            setShowEmptyDayConfirm(false)
            setSelectedDate("")
        } catch (error) {
            // TODO: error handling
            console.log("error", error)
        }
    }

    async function setDisabledToFree() {
        if (!props.cube.reservations) {
            return
        }
        try {
            const response = await axiosClient.delete(`/cube/disabledday/${props.cube._id}`, {
                data: {
                    disabledDay: selectedDate,
                },
            })
            props.setCube(response.data)
            setShowDisabledDayConfirm(false)
            setSelectedDate("")
        } catch (error) {}
    }

    function handleSelectEvent(event: any) {
        switch (event.type) {
            case EventTypes.RESERVATION:
                history.push(`/admin/reservations/${event.id}`)
                break
            case EventTypes.DISABLED:
                setSelectedDate(event.start)
                setShowDisabledDayConfirm(true)
                break
        }
    }

    return (
        <Row>
            <Col xs={12} md={6}>
                <div className="tile">
                    <EventCalendar
                        events={createEvent()}
                        onSelectSlotFunction={(date: any) => {
                            setSelectedDate(date)
                            setShowEmptyDayConfirm(true)
                        }}
                        handleSelectEvent={handleSelectEvent}
                    />
                </div>
            </Col>

            <ConfirmModal
                show={showEmptyDayConfirm && !checkIsReserved()}
                onHide={() => setShowEmptyDayConfirm(false)}
                title={moment(selectedDate).format("YYYY.MM.DD.")}
                bodyText={<p>Set to disabled day?</p>}
                confirmFunction={setDateToDisabled}
            />
            <ConfirmModal
                show={showDisabledDayConfirm}
                onHide={() => setShowDisabledDayConfirm(false)}
                title={moment(selectedDate).format("YYYY.MM.DD.")}
                bodyText={<p>Remove from disabled days?</p>}
                confirmFunction={setDisabledToFree}
            />
        </Row>
    )
}

export default CubeCalendar
