//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// Own components
import ReservationsTable from "../reservations/reservation-table.component"

// axios
import axiosClient from "../../api/api"

// Interfaces, enums
import { IReservation } from "../../interfaces/cubes"
import { IUser } from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    user: IUser
}

/**
 * display reservations of one user
 * @param props user
 * @returns
 */
function UserReservations(props: IProps) {
    // reservations of the user from the database
    const [reservations, setReservations] = useState<IReservation[]>([])

    useEffect(() => {
        // fetch reservations
        fetchReservations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * fetch reservations of the user from the database
     */
    async function fetchReservations() {
        try {
            const response = await axiosClient.get(`/reservation?user=${props.user._id}`)
            setReservations(response.data)
        } catch (error) {
            console.log("Failed to fetch reservations")
        }
    }
    return (
        <Row>
            <Col>
                <div className="table-tile">
                    <ReservationsTable reservations={reservations} hiddenColumns={["cube", "endDate", "id", "status", "createdAt"]} />
                </div>
            </Col>
        </Row>
    )
}

export default UserReservations
