//  Modules from the React eco-system
import React, { useMemo } from "react"

// Own components
import TableDisplayer from "../table.component"

// Interfaces, enums
import { ISuperAdmin } from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    admins: ISuperAdmin[]
    hiddenColumns?: string[]
}

function AdminsTable(props: IProps) {
    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "firstname",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("name"),
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("email"),
            },
        ],

        [props.hiddenColumns]
    )

    return (
        <Row>
            <Col>
                <div className="table-tile">{props.admins && <TableDisplayer columns={columns} data={props.admins} />}</div>{" "}
            </Col>
        </Row>
    )
}

export default AdminsTable
