//  Modules from the React eco-system
import { useMemo } from "react"
import { Link } from "react-router-dom"

// Third-party modules
import moment from "moment"

// Own components
import { dateFilter, textFilter } from "../column-filter.component"
import TableDisplayer from "../table.component"

// Interfaces, enums
import { ICube, IRegion } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import Icon from "@mdi/react"
import { mdiTune, mdiMapLegend } from "@mdi/js"
import { Row, Col } from "react-bootstrap"

interface IProps {
    regions: IRegion[]
    hiddenColumns?: string[]
}
/**
 * display regions in a table
 * @param props regions[]
 * @returns
 */
function RegionsTable(props: IProps) {
    const columns = useMemo(
        () => [
            {
                Header: "Details",

                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/admin/regions/${data.row.original._id}`}>
                                    <span className="icon-container btn orange">
                                        <Icon path={mdiTune} size={1} />
                                    </span>{" "}
                                </Link>

                                {data.value}
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: (data: any) => {
                    return <Link to={`/admin/regions/${data.row.original._id}`}>{data.value}</Link>
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("name"),
            },
            {
                Header: "Image",
                accessor: "image",
                Cell: (data: any) => {
                    return (
                        <div>
                            {data.value ? (
                                <img className="table-image" src={data.value} alt="" />
                            ) : (
                                <div className="table-icon-container">
                                    <Icon path={mdiMapLegend} size={1} />
                                </div>
                            )}
                        </div>
                    )
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("image"),
            },
            {
                Header: "Operator",
                isHidden: true,
                accessor: (originalRow: IRegion): string => {
                    return originalRow.operator ? `${originalRow.operator.firstname} ${originalRow.operator.lastname}` : ""
                },
                Cell: (data: any) => {
                    return data.value ? <Link to={`/admin/operators/${data.row.original.operator._id}`}>{data.value}</Link> : <p>-</p>
                },
                Filter: textFilter,
            },
            {
                Header: "Cubes",
                accessor: (data: IRegion) => {
                    return (
                        <div>
                            {(data.cubes as ICube[]).map((item: ICube) => {
                                return (
                                    <span key={item._id}>
                                        {" "}
                                        <Link to={`/admin/cubes/${item._id}`}>{item.name}</Link>{" "}
                                    </span>
                                )
                            })}
                        </div>
                    )
                },
                disableFilters: true,
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("cubes"),
            },
            {
                Header: "Id",
                accessor: "_id",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("id"),
                width: 10,
            },
            {
                Header: "Created at",
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("createdAt"),
            },
        ],

        [props.hiddenColumns]
    )

    return (
        <Row>
            <Col>
                <div className="table-tile">{props.regions && <TableDisplayer columns={columns} data={props.regions} />}</div>
            </Col>
        </Row>
    )
}

export default RegionsTable
