//  Modules from the React eco-system
import React from "react"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// style, bootstrap, icons
import { Modal, Button, Form } from "react-bootstrap"

interface IProps {
    show: boolean
    handleClose: any
    productid: string
    setProduct: Function
}

const FormInitialValues = {
    image: undefined,
}

function ProductImageUploader(props: IProps) {
    const formik = useFormik({
        initialValues: FormInitialValues,
        validationSchema: Yup.object({
            image: Yup.mixed().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (values.image) {
                    const formData = new FormData()
                    formData.append("type", "product")
                    formData.append("image", values.image)

                    const response = await axiosClient.patch(`/product/${props.productid}/image`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })

                    props.setProduct(response.data)
                    props.handleClose()
                    toast.success("The image is uploaded")
                    resetForm({
                        values: FormInitialValues,
                    })
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formProduct">
                            <Form.Label>Image:</Form.Label>
                            <Form.Control
                                onChange={(evt) => {
                                    formik.setFieldValue("image", (evt.target as any).files[0])
                                }}
                                name="image"
                                type="file"
                            />
                            {formik.touched.image && formik.errors.image ? <div className="input-error">{formik.errors.image}</div> : null}
                        </Form.Group>

                        <Button className="orange" type="submit">
                            Upload
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ProductImageUploader
