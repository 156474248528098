//  Modules from the React eco-system
import React, { useContext } from "react"

// axios
import axiosClient from "../../api/api"
import axios, { AxiosError } from "axios"

// Third-party modules
import { useFormik } from "formik"
import moment from "moment"
import { toast } from "react-toastify"

// Redux, store
import { UserContext } from "../../store/UserProvider"

// iinterfaces, enums
import { IOrderItem, IReservation, IOrder } from "../../interfaces/cubes"
import { ERoles } from "../../constants/enum"

// styles, bootstrap, icons
import { Modal, Button, Accordion, Form, Popover, OverlayTrigger } from "react-bootstrap"

interface IProps {
    orderItem: IOrderItem
    show: boolean
    handleClose: any
    setReservation: Function
}

/**
 * display order unit details and actions (update, delete)
 * @param props
 * @returns
 */
function OrderUnitAction(props: IProps) {
    //Logged in user
    const { user } = useContext(UserContext)

    /**
     * delete order unit
     */
    async function deleteOrderUnit() {
        try {
            const response = await axiosClient.delete(`/reservation//orderitem/${props.orderItem._id}`)

            props.handleClose()
            props.setReservation((prev: IReservation) => {
                const newOrders = prev.orders.map((order) => {
                    if (order._id === response.data._id) {
                        return response.data
                    } else {
                        return order
                    }
                })
                return { ...prev, orders: newOrders }
            })
            toast.success("Order unit is removed")
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Failed to delete the order item")
            }
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.orderItem.name,
            description: props.orderItem.description,
            price: props.orderItem.price,
            quantity: props.orderItem.quantity,
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                const updateData = {
                    ...(formik.values.name && formik.values.name !== props.orderItem.name && { name: values.name }),
                    ...(formik.values.description && formik.values.description !== props.orderItem.description && { description: values.description }),
                    ...(formik.values.price && formik.values.price !== props.orderItem.price && { price: values.price }),
                    ...(formik.values.quantity && formik.values.quantity !== props.orderItem.quantity && { quantity: values.quantity }),
                }
                if (Object.keys(updateData).length) {
                    const response = await axiosClient.patch(`/reservation/orderitem/${props.orderItem._id}`, { updateData: updateData })
                    props.setReservation((prev: IReservation) => {
                        return {
                            ...prev,
                            orders: (prev.orders as IOrder[]).map((order) => {
                                if (order._id === response.data._id) {
                                    return response.data
                                } else {
                                    return order
                                }
                            }),
                        }
                    })
                    props.handleClose()
                    toast.success("The order item is updated")
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    const err = error as AxiosError
                    toast.success(err.response?.data)
                } else {
                    toast.success("Failed to update the order item")
                }
            }
        },
    })

    // form for services
    const serviceUpdateForm = (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="formRegionName">
                <Form.Label>Name</Form.Label>
                <Form.Control onChange={formik.handleChange} name="name" onBlur={formik.handleBlur} value={formik.values.name} type="text" placeholder="Name of the service..." />
                {formik.touched.name && formik.errors.name ? <p className="input-error">{formik.errors.name}</p> : null}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formRegionName">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={formik.handleChange}
                    name="description"
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    type="text"
                    placeholder="Description..."
                />
                {formik.touched.description && formik.errors.description ? <p className="input-error">{formik.errors.description}</p> : null}
            </Form.Group>
            <Button className="orange" type="submit">
                Update
            </Button>
        </Form>
    )

    // form for products
    const productUpdateForm = (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="formProduct">
                <Form.Label>Quantity:</Form.Label>
                <Form.Control onChange={formik.handleChange} name="quantity" onBlur={formik.handleBlur} value={formik.values.quantity} type="number" placeholder="Quantity..." />
                {formik.touched.quantity && formik.errors.quantity ? <div>{formik.errors.quantity}</div> : null}
            </Form.Group>
            <Button className="orange" type="submit">
                Update
            </Button>
        </Form>
    )

    /**
     * popover for the order item deletion
     */
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Delete order item</Popover.Header>
            <Popover.Body>
                <div>
                    <p>Delete this order item?</p>
                    <Button className="btn orange" onClick={deleteOrderUnit}>
                        Delete
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    )

    return (
        <>
            <Modal className="order-unit-action" show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <h2>Order item</h2>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>
                            <span className="field-name">Price: </span> {props.orderItem.price}
                        </p>
                        <p>
                            <span className="field-name">Status: </span> {props.orderItem.status}
                        </p>
                        <p>
                            <span className="field-name">Created:</span> {moment(props.orderItem.createdAt).format("YYYY. MM. DD., HH:mm")}
                        </p>
                    </div>

                    {props.orderItem.type === "ProductOrderItem" && props.orderItem.product && (
                        <div>
                            <p>
                                <span className="field-name">Product:</span> {props.orderItem.product.name}
                            </p>
                            <p>
                                <span className="field-name">Quantity: </span>
                                {props.orderItem.quantity}
                            </p>
                            <p>
                                <span className="field-name">User price:</span> {`${props.orderItem.product.user_price} Ft`}
                            </p>
                            <p>
                                <span className="field-name">Purchase price:</span> {`${props.orderItem.product.purchase_price} Ft`}
                            </p>
                            <p>
                                <span className="field-name">Enabled:</span> {props.orderItem.product.enabled ? "Igen" : "Nem"}
                            </p>
                            <p>
                                <span className="field-name">Description:</span> {props.orderItem.product.description}
                            </p>
                            <p>
                                <span className="field-name">Supplier: </span> {`${props.orderItem.product.supplier.firstname} ${props.orderItem.product.supplier.lastname}`}
                            </p>
                        </div>
                    )}
                    {props.orderItem.type === "ServiceOrderItem" && (
                        <div>
                            <p>
                                <span className="field-name">Service:</span> {props.orderItem.name}
                            </p>
                            <p>
                                <span className="field-name">Description:</span> {props.orderItem.description}
                            </p>
                        </div>
                    )}

                    {user.role === ERoles.SUPERADMIN ? (
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Edit</Accordion.Header>
                                <Accordion.Body>{props.orderItem.type === "ServiceOrderItem" ? serviceUpdateForm : productUpdateForm}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Delete</Accordion.Header>
                                <Accordion.Body>
                                    <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose>
                                        <Button variant="danger">Delete</Button>
                                    </OverlayTrigger>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    ) : (
                        <></>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderUnitAction
