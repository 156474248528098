import { useState } from "react"
import { ICube, IReservation } from "../../interfaces/cubes"
import moment from "moment"
import Select from "react-select"
import axiosClient from "../../api/api"
import Icon from "@mdi/react"
import { mdiCalendarBlankOutline, mdiChevronDown, mdiChevronUp } from "@mdi/js"
import { EReservationStatus } from "../../constants/enum"
import { toast } from "react-toastify"

interface IProps {
    reservation: IReservation
    setReservation: Function
}
interface IStatusOption {
    value: EReservationStatus
    label: EReservationStatus
}
// options for the status selector
const statusOptions: IStatusOption[] = [
    { value: EReservationStatus.OPEN, label: EReservationStatus.OPEN },
    { value: EReservationStatus.CONFIRMED, label: EReservationStatus.CONFIRMED },
    { value: EReservationStatus.READY, label: EReservationStatus.READY },
    { value: EReservationStatus.STARTED, label: EReservationStatus.STARTED },
    { value: EReservationStatus.COMPLETE, label: EReservationStatus.COMPLETE },
    { value: EReservationStatus.PAYMENT_REJECTED, label: EReservationStatus.PAYMENT_REJECTED },
]

/**
 * component for the main infos of one reservation
 * @param props
 * @returns
 */
function ReservationMainInfos(props: IProps) {
    // show/hide status selector
    const [showStatusSelector, setShowStatusSelector] = useState<boolean>(false)

    // change the status of the reservation
    async function changeStatus(status: EReservationStatus) {
        try {
            const response = await axiosClient.patch(`/reservation/${props.reservation._id}`, { updateData: { status: status } })
            props.setReservation(response.data)
            toast.success("Reservation status is updated")
        } catch (error) {
            toast.success("Failed to change status")
        }
    }

    return (
        <div className="tile reservation-maininfos">
            <div className="orange-icon-container">
                <Icon path={mdiCalendarBlankOutline} size={1} />
            </div>

            <p>{`${moment(props.reservation.startDate).format("YYYY. MMM. DD.")} - ${moment(props.reservation.endDate).format("YYYY. MMM. DD.")}`}</p>
            <h2>{(props.reservation.cube as ICube).name}</h2>
            <div className="status-container">
                <div className="status-info">
                    <p className={`status ${props.reservation.status}`}>{props.reservation.status}</p>
                    <span className="status-chevron-container" onClick={() => setShowStatusSelector(!showStatusSelector)}>
                        <Icon path={showStatusSelector ? mdiChevronUp : mdiChevronDown} size={1} />
                    </span>
                </div>

                {showStatusSelector && (
                    <Select
                        className="select-status"
                        options={statusOptions}
                        onChange={(option) => option && changeStatus(option.value as EReservationStatus)}
                        value={{ value: props.reservation.status, label: props.reservation.status }}
                    />
                )}
            </div>

            <p>Order items: {props.reservation.orders.map((x) => x.order_items).flat().length}</p>
            <p>Created: {moment(props.reservation.createdAt).format("YYYY. MMM. DD.")}</p>
            {props.reservation.participants && props.reservation.participants.adult ? (
                <p>
                    Participants:{" "}
                    {`${props.reservation.participants.adult ? props.reservation.participants.adult + " adult(s)," : ""}  ${
                        props.reservation.participants.child ? props.reservation.participants.child + " child(ren)," : ""
                    }  ${props.reservation.participants.baby ? props.reservation.participants.baby + " baby(s)," : ""}  ${
                        props.reservation.participants.animal ? props.reservation.participants.animal + " animal(s)" : ""
                    } `}
                </p>
            ) : (
                <></>
            )}
            {props.reservation.firstOpen ? <p>First open: {moment(props.reservation.firstOpen).format("YYYY. MMM. DD.")}</p> : <></>}
        </div>
    )
}

export default ReservationMainInfos
