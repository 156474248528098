//  Modules from the React eco-system
import React from "react"

// Third-party modules
import moment from "moment"

// Interfaces, enums
import { IRegion } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import Icon from "@mdi/react"
import { mdiMapLegend } from "@mdi/js"

interface IProps {
    region: IRegion
}
function RegionInfo(props: IProps) {
    return (
        <div className="tile region-info">
            {props.region.image ? (
                <div className="region-image-container">
                    <img className="region-image" src={props.region.image} alt="region" />
                </div>
            ) : (
                <div className="large-icon-container">
                    <Icon path={mdiMapLegend} />
                </div>
            )}

            <h2>{props.region.name}</h2>
            <p>id: {props.region._id}</p>
            {props.region.location && props.region.location.coordinates.length ? (
                <p>Center of the region: {`${props.region.location.coordinates[0].toFixed(5)} - ${props.region.location.coordinates[1].toFixed(5)}`}</p>
            ) : (
                <></>
            )}
            <p>{`${props.region.cubes.length} cube(s)`}</p>
            <p>{`${props.region.suppliers.length} supplier(s)`}</p>
            <p>Created: {moment(props.region.createdAt).format("YYYY.MM.DD.")}</p>
        </div>
    )
}

export default RegionInfo
