//  Modules from the React eco-system
import React from "react"

// Own components
import CreateProduct from "./create-product.component"
// Interfaces, enums
import { ISupplier } from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    supplier: ISupplier
    setSupplier: Function
}

function SupplierProducts(props: IProps) {
    return (
        <Row>
            <Col>
                <div className="tile">
                    <CreateProduct supplierId={props.supplier._id} setSupplier={props.setSupplier} />
                </div>
            </Col>
        </Row>
    )
}

export default SupplierProducts
