import { EActivityAction } from "../constants/enum"
import { IOperator, ISupplier, IUser, IBaseAdmin, IParticipants } from "./persons"

export interface IRegion {
    _id: string
    name: string
    description: string
    operator: IOperator
    cubes: string[] | ICube[]
    suppliers: string[] | ISupplier[]
    createdAt: Date
    image: string
    location?: {
        type: string
        coordinates: number[]
    }
    warehouse?: IWarehouse | string
}

export interface IWarehouse {
    _id: string
    name: string
    description: string
    region: IRegion | string
    products: IWarehouseProduct[]
}

interface IWarehouseProduct {
    product: IProduct
    quantity: number
}

export interface ICube {
    // assets: {}
    _id: string
    name: string
    price: number
    region: IRegion
    description: string
    short_description: string
    fix_services: string[]
    reservations: string | IReservation[]
    disabled_days: Date[]
    images: string[]
    location: {
        type: string
        coordinates: number[]
    }
    cube_features: ICubeFeature[]
    highlighted_features: string[]
    terms_of_use: string
    cancellation_policy: string
    // shop products
    shopProducts: IShopProduct[]
    // Home assistant infos
    mqtt_name: string
    home_assistant_ip: string
    water_tank_size: number
    allowed_water_consumption: number
}

export interface IShopProduct {
    product: IProduct
    quantity: number
}

export interface IReservation {
    _id: string
    user: string | IUser
    cube: string | ICube
    region: string
    startDate: Date
    endDate: Date
    status: "payment rejected" | "open" | "confirmed" | "ready" | "complete" | "started"
    userComment: String
    orders: IOrder[]
    createdAt: Date
    increment_id: string
    participants: IParticipants
    // Home Assistant infos
    firstOpen?: Date
    lastClose?: Date
    waterPctStart?: number
    waterPctLastClose?: number
}

export interface IProduct {
    _id: string
    name: string
    supplier: ISupplier
    description: string
    short_description: string
    category: string
    main_category?: IMainCategory
    user_price: number
    purchase_price: number
    image: string
    enabled: boolean
    createdAt: Date
    highlightedInApp: boolean
    shopProduct: boolean
    quantityInCube?: number
}

export interface IStoredProduct extends IProduct {
    quantity: number
}

export interface IOrder {
    _id: string
    reservation: IReservation
    status: "open" | "accepted" | "complete"
    isPayed?: boolean
    order_items: []
    images: string[]
    createdAt: Date
}

export interface IOrderItem {
    _id: string
    name?: string
    description?: string
    defaultService?: boolean
    quantity?: number
    product?: IProduct
    order: IOrder
    status: "open" | "incoming" | "prepared" | "complete"
    price: number
    type: "ServiceOrderItem" | "ProductOrderItem"
    createdAt: Date
    updatedAt: Date
}

export interface IProductOrderItem {
    _id: string
    quantity: number
    product: IProduct
    order: IOrder
    status: "open" | "incoming" | "prepared" | "complete"
    price: number
    type: "ProductOrderItem"
    createdAt: Date
    updatedAt: Date
}

export interface IMainCategory {
    _id: string
    name: string
    second_name: string
    description: string
    image: string
    products: IProduct
}

export enum EventTypes {
    RESERVATION = "reservation",
    DISABLED = "disabled",
}

export interface IActivityLog {
    actor: IBaseAdmin
    entity: any
    data: any
    entityType: string
    action: EActivityAction
    description: string
}

export interface ICubeFeature {
    _id: string
    name: string
    image: string
}
