//  Modules from the React eco-system
import { useMemo } from "react"
import { Link } from "react-router-dom"

// Third-party modules
import moment from "moment"

// Own components
import { dateFilter, textFilter } from "../column-filter.component"
import TableDisplayer from "../table.component"

// Interfaces, enums
import { IProduct, IRegion } from "../../interfaces/cubes"
import { ISupplier } from "../../interfaces/persons"

// Styles, bootstrap, icons
import Icon from "@mdi/react"
import { mdiTune, mdiCheck, mdiClose } from "@mdi/js"
import { Row, Col } from "react-bootstrap"

interface IProps {
    suppliers: ISupplier[]
    hiddenColumns?: string[]
}

function SuppliersTable(props: IProps) {
    const columns = useMemo(
        () => [
            {
                Header: "Details",
                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/admin/suppliers/${data.row.original._id}`}>
                                    <span className="icon-container btn orange">
                                        <Icon path={mdiTune} size={1} />
                                    </span>{" "}
                                </Link>

                                {data.value}
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: "Name",
                accessor: "firstname",
                Cell: (data: any) => {
                    return (
                        <div>
                            <Link to={`/admin/suppliers/${data.row.original._id}`}>{data.value}</Link>
                        </div>
                    )
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("name"),
            },
            {
                Header: "Email",
                accessor: "email",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("email"),
            },
            {
                Header: "Phone",
                accessor: "phone",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("phone"),
            },
            {
                Header: "Tax number",
                accessor: "tax_number",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("taxNumber"),
            },
            {
                Header: "Company",
                accessor: "company",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("company"),
            },
            {
                Header: "Regions",
                accessor: (data: any) => {
                    const regions: string = (data.regions as IRegion[])
                        .map((region) => {
                            return region.name
                        })
                        .join(", ")

                    return regions
                },
                Cell: (data: any) => {
                    const displayed = (
                        <>
                            {(data.row.original.regions as IRegion[]).map((region) => {
                                return (
                                    <span>
                                        <Link to={`/admin/regions/${region._id}`}>{region.name}</Link>
                                        {", "}
                                    </span>
                                )
                            })}
                        </>
                    )
                    return displayed
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("regions"),
            },
            {
                Header: "Products",
                accessor: (data: any) => {
                    const displayed: string = (data.products as IProduct[])
                        .map((product) => {
                            return product.name
                        })
                        .join(", ")

                    return displayed
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("products"),
            },
            {
                Header: "Registered at",
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("registeredAt"),
            },
            {
                Header: "Email verified",
                accessor: "isVerified",
                Cell: (data: any) => {
                    return data.value ? <Icon path={mdiCheck} size={1} /> : <Icon path={mdiClose} size={1} />
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("emailVerified"),
            },
        ],

        [props.hiddenColumns]
    )

    return (
        <Row>
            <Col>
                <div className="table-tile">{props.suppliers && <TableDisplayer columns={columns} data={props.suppliers} />}</div>
            </Col>
        </Row>
    )
}

export default SuppliersTable
