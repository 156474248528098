//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// Own components
import AdminInfo from "../admin-info.component"

// Interfaces, enums
import { IRegion } from "../../interfaces/cubes"
import { IOperator } from "../../interfaces/persons"

// Axios
import axiosClient from "../../api/api"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    region: IRegion
}

function RegionDetailsOperator(props: IProps) {
    const [operator, setOperator] = useState<IOperator>()

    useEffect(() => {
        fetchOperator()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.region])

    async function fetchOperator() {
        try {
            if (props.region.operator) {
                const response = await axiosClient.get(`/operator/${props.region.operator}`)
                setOperator(response.data)
            }
        } catch (error) {
            console.log("failed to fetch operator")
        }
    }

    return (
        <Row>
            <Col>{operator ? <AdminInfo admin={operator} /> : <p>The region has no operator</p>}</Col>
        </Row>
    )
}

export default RegionDetailsOperator
