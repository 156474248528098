import React from "react"
import { Modal, Button } from "react-bootstrap"

interface IProps {
    show: boolean
    onHide: any
    title: string
    bodyText: JSX.Element
    confirmFunction: any
}

function ConfirmModal(props: IProps) {
    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.bodyText}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Nem
                </Button>
                <Button variant="primary" onClick={props.confirmFunction}>
                    Igen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmModal
