// Modules from the React eco-system
import React, { useState } from "react"
import { useHistory } from "react-router-dom"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Own components
import ProductImageUploader from "./product-image-uploader-modal.component"
import ProductForm from "../forms/product.form"

// axios
import axiosClient from "../../api/api"
import axios, { AxiosError } from "axios"

// interfaces, enums
import { IProduct } from "../../interfaces/cubes"

// bootstrap, icons
import { Button, Row, Col, OverlayTrigger, Popover } from "react-bootstrap"

interface IProps {
    product: IProduct
    setProduct: Function
}

/**
 * form and actions for updating a product
 * @param props
 * @returns
 */
function ProductUpdate(props: IProps) {
    const history = useHistory()

    // show image uploader
    const [showUploader, setShowUploader] = useState<boolean>(false)

    /**
     * popover for the product deletion
     */
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Delete product</Popover.Header>
            <Popover.Body>
                <div>
                    <p>Delete this product?</p>
                    <Button className="btn orange" onClick={deleteProduct}>
                        Delete
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    )

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.product.name,
            description: props.product.description,
            userPrice: props.product.user_price,
            purchasePrice: props.product.purchase_price,
            shortDescription: props.product.short_description,
            category: props.product.category,
            enabled: props.product.enabled,
            highlightedInApp: props.product.highlightedInApp,
            shopProduct: props.product.shopProduct,
            mainCategory: props.product.main_category ? { label: props.product.main_category.name, values: props.product.main_category._id } : { label: "", value: "" },
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
        }),
        onSubmit: async (values) => {
            try {
                const updateData = {
                    ...(formik.values.name && formik.values.name !== props.product.name && { name: values.name }),
                    ...(formik.values.description && formik.values.description !== props.product.description && { description: values.description }),
                    ...(formik.values.shortDescription && formik.values.shortDescription !== props.product.short_description && { short_description: values.shortDescription }),
                    ...(formik.values.category && formik.values.category !== props.product.category && { category: values.category }),
                    ...(formik.values.userPrice && formik.values.userPrice !== props.product.user_price && { user_price: values.userPrice }),
                    ...(formik.values.purchasePrice && formik.values.purchasePrice !== props.product.purchase_price && { purchase_price: values.purchasePrice }),
                    ...(formik.values.enabled !== undefined && formik.values.enabled !== props.product.enabled && { enabled: values.enabled }),
                    ...(formik.values.highlightedInApp !== undefined && formik.values.highlightedInApp !== props.product.highlightedInApp && { highlightedInApp: values.highlightedInApp }),
                    ...(formik.values.shopProduct !== undefined && formik.values.shopProduct !== props.product.shopProduct && { shopProduct: values.shopProduct }),
                    ...(formik.values.mainCategory.value && formik.values.mainCategory.value !== props.product.main_category?._id && { main_category: formik.values.mainCategory.value }),
                }
                if (Object.keys(updateData).length) {
                    const response = await axiosClient.patch(`/product/${props.product._id}`, updateData)
                    props.setProduct(response.data)
                    toast.success("The product is updated")
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    const err = error as AxiosError
                    toast.error(err.response?.data)
                } else {
                    toast.error("Failed to update the product")
                }
            }
        },
    })

    /**
     * delete product
     */
    async function deleteProduct() {
        try {
            await axiosClient.delete(`/product/${props.product._id}`)
            history.push("/admin/products")
            toast.success("The product is removed")
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Failed to delete the product")
            }
        }
    }

    return (
        <Row>
            <Col>
                <div className="tile">
                    <Row>
                        <Col>
                            <ProductForm formik={formik} />
                        </Col>
                    </Row>

                    <Button className="btn orange" onClick={() => setShowUploader(true)}>
                        Upload image
                    </Button>
                </div>

                <Row>
                    <Col>
                        <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose>
                            <Button className="orange">Delete Product</Button>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <ProductImageUploader show={showUploader} handleClose={() => setShowUploader(false)} productid={props.product._id} setProduct={props.setProduct} />
            </Col>
        </Row>
    )
}

export default ProductUpdate
