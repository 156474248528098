// interfaces, enums
import { IPaymentIntent } from "../../interfaces/stripe"

// style, bootstrap, icons
import { Modal, Col, Row } from "react-bootstrap"
import {} from "react-bootstrap"

interface IProps {
    show: boolean
    handleClose: any
    paymentIntent: IPaymentIntent
}

function PaymentIntentModal(props: IProps) {
    const data = {
        "Payment details": [
            {
                label: "Amount",
                data: `${(props.paymentIntent.amount / 100).toLocaleString()} ${props.paymentIntent.currency.toUpperCase()}`,
            },
            {
                label: "Status",
                data: props.paymentIntent.status,
            },
            {
                label: "ID",
                data: props.paymentIntent.id,
            },
        ],
        Metadata: [
            {
                label: "Cube",
                data: props.paymentIntent.metadata.cube,
            },
            {
                label: "Reservation id",
                data: props.paymentIntent.metadata.reservation_increment_id,
            },
            {
                label: "Order id",
                data: props.paymentIntent.metadata.order,
            },
            {
                label: "Order Items",
                data: props.paymentIntent.metadata.order_items?.split().length,
            },
            {
                label: "Description",
                data: props.paymentIntent.metadata.description,
            },
        ],
        Customer: [
            {
                label: "Name",
                data: props.paymentIntent.customer?.name,
            },
            {
                label: "Email",
                data: props.paymentIntent.customer?.email,
            },
        ],
    }

    function renderData() {
        return Object.entries(data).map(([key, value]) => {
            return (
                <div className="section">
                    <h5 className="section-title">{key}</h5>
                    {value.map((row) => {
                        return (
                            <Row className="payment-intent-row">
                                <Col xs={4} className="payment-intent-col">
                                    <p className="payment-intent-label">{row.label}</p>
                                </Col>
                                <Col className="payment-intent-col">
                                    <p className="payment-intent-data">{row.data}</p>
                                </Col>
                            </Row>
                        )
                    })}
                </div>
            )
        })
    }
    return (
        <Modal show={props.show} onHide={props.handleClose} centered className="payment-intent-modal">
            <Modal.Header closeButton className={props.paymentIntent.status}>
                <Modal.Title>Payment Intent</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderData()}</Modal.Body>
        </Modal>
    )
}

export default PaymentIntentModal
