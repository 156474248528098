//  Modules from the React eco-system
import React from "react"
import { useHistory } from "react-router-dom"

// Third-party modules
import { FormikValues, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Own components
import ProductForm from "../forms/product.form"

// Axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// Interfaces, enums
import { ISupplier } from "../../interfaces/persons"

interface IProps {
    setSupplier?: Function
    supplierId?: string
}

function CreateProduct(props: IProps) {
    const history = useHistory()

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            shortDescription: "",
            userPrice: "",
            purchasePrice: "",
            mainCategory: { label: "", value: "" },
            enabled: true,
            highlightedInApp: false,
            shopProduct: false,
            ...(!props.setSupplier && { supplier: { label: "", value: "" } }),
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            shortDescription: Yup.string().required("Required"),
            userPrice: Yup.number().required("Required"),
            purchasePrice: Yup.number().required("Required"),
            // TODO: validation on supplier and mainCategory fields
            // ...(!props.setSupplier && { supplier: Yup.object().required("Required") }),
        }),
        onSubmit: async (values: FormikValues, actions: any) => {
            try {
                const productData = {
                    name: values.name,
                    description: values.description,
                    short_description: values.shortDescription,
                    user_price: values.userPrice,
                    purchase_price: values.purchasePrice,
                    supplier: props.supplierId ? props.supplierId : values.supplier.value,
                    main_category: values.mainCategory.value,
                    enabled: values.enabled,
                    highlightedInApp: values.highlightedInApp,
                    shopProduct: values.shopProduct,
                }
                const response = await axiosClient.post("/product", productData)

                toast.success("The product is created")
                actions.resetForm()
                props.setSupplier &&
                    props.setSupplier((prev: ISupplier) => {
                        return { ...prev, products: [...prev.products, response.data] }
                    })
                    history.push(`/admin/products/${response.data._id}`)
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    return (
        <div>
            <ProductForm formik={formik} />
        </div>
    )
}

export default CreateProduct
