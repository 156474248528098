import { useMemo } from "react"
import { Button } from "react-bootstrap"
import { IOrderItem, IProduct } from "../../interfaces/cubes"
import TableDisplayer from "../table.component"
import Icon from "@mdi/react"
import { mdiTune } from "@mdi/js"
import { textFilter } from "../column-filter.component"
import { useHistory } from "react-router-dom"

interface IProps {
    orderItems: IOrderItem[]
    setProductOrderItems: Function
}

function ProductOrdersTable(props: IProps) {
    const history = useHistory()

    const columns = useMemo(
        () => [
            {
                Header: "Details",
                Cell: (data: any) => {
                    return (
                        <Button
                            className="orange"
                            onClick={() => {
                                history.push(`/admin/products/${data.row.original.product._id}`)
                            }}>
                            <Icon path={mdiTune} size={1} />
                        </Button>
                    )
                },
                disableFilters: true,
            },

            {
                Header: "Product",
                accessor: (data: any) => {
                    return data.type === "ServiceOrderItem" ? data.name : (data.product as IProduct).name
                },
                Filter: textFilter,
            },

            {
                Header: "Status",
                accessor: "status",

                Filter: textFilter,
            },

            {
                Header: "Quantity",
                accessor: "quantity",
                Cell: (data: any) => {
                    return <span>{data.value ? data.value : "-"}</span>
                },
                disableFilters: true,
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return <div>{props.orderItems && <TableDisplayer columns={columns} data={props.orderItems} />}</div>
}

export default ProductOrdersTable
