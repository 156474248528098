//  Modules from the React eco-system
import { useState, useEffect } from "react"

// Own components
import ReservationsTable from "../reservations/reservation-table.component"

// Axios
import axiosClient from "../../api/api"

// Interfaces, enums
import { IReservation, ICube } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    cube: ICube
    setCube: Function
}
/**
 * component to show the reservations of the current cube
 * @param props
 * @returns
 */
function CubeReservations(props: IProps) {
    // reservations of the cube from the fetch
    const [reservations, setReservations] = useState<IReservation[]>([])

    useEffect(() => {
        // fetch reservations
        fetchReservations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cube])

    /**
     * fetch reservations of the current cube
     */
    async function fetchReservations() {
        try {
            const response = await axiosClient.get(`/reservation?cube=${props.cube._id}`)
            setReservations(response.data)
        } catch (error) {
            console.log("Failed to fetch reservations")
        }
    }
    return (
        <Row>
            <Col>
                <div className="tile">
                    <ReservationsTable reservations={reservations} hiddenColumns={["cube", "endDate", "id", "status", "createdAt"]} />
                </div>
            </Col>
        </Row>
    )
}

export default CubeReservations
