import React from "react"
import { ICubeFeature, ICube } from "../../interfaces/cubes"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// style, bootstrap, icons
import { Button, Form, Row, Col } from "react-bootstrap"

interface IProps {
    cubeId: string
    cubeFeatures: ICubeFeature[]
    setCube: Function
}

function AddCubeFeature(props: IProps) {
    const formik = useFormik({
        initialValues: {
            name: "",
            image: undefined,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            image: Yup.mixed().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (values.name && values.image) {
                    const formData = new FormData()
                    formData.append("type", "cubefeature")
                    formData.append("name", values.name)
                    formData.append("cube", props.cubeId)
                    formData.append("image", values.image)
                    const response = await axiosClient.post(`/cubefeature`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    props.setCube((prev: ICube) => {
                        return { ...prev, cube_features: [...prev.cube_features, response.data] }
                    })
                    toast.success("New cube feature is added")
                }

                resetForm()
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })
    return (
        <div>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="formCategoryImage">
                    <Form.Label>Image:</Form.Label>
                    <Form.Control
                        onChange={(evt) => {
                            formik.setFieldValue("image", (evt.target as any).files[0])
                        }}
                        name="image"
                        type="file"
                    />
                    {formik.touched.image && formik.errors.image ? <div className="input-error">{formik.errors.image}</div> : null}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCategory">
                    <Form.Label>Name</Form.Label>
                    <Form.Control onChange={formik.handleChange} name="name" onBlur={formik.handleBlur} value={formik.values.name} type="text" placeholder="Name of the feature..." />
                    {formik.touched.name && formik.errors.name ? <p className="input-error">{formik.errors.name}</p> : null}
                </Form.Group>
                <Row>
                    <Col>{formik.values.image && <img className="preview-image" src={URL.createObjectURL(formik.values.image)} alt="uploaded" />}</Col>
                    <Col>
                        <p>{formik.values.name}</p>
                    </Col>
                </Row>
                <Button className="orange" type="submit">
                    Upload
                </Button>
            </Form>
        </div>
    )
}

export default AddCubeFeature
